// React imports
import React, { useState, useEffect } from 'react';

import { clearGraphicsLayer } from '../../utils/map';
// import { useLocalStorage } from '../../utils/storage';

import Card from 'calcite-react/Card';
import Form, { FormControl, FormControlLabel } from 'calcite-react/Form';
import Select from 'calcite-react/Select';
import { MenuItem } from 'calcite-react/Menu';
import TextField from 'calcite-react/TextField';
import Button from 'calcite-react/Button';
import { Alert } from '../esri/Alert';

// Styled & Motion Components
import styled from 'styled-components';
import { loadModules } from 'esri-loader';

// line styles
import solid from '../../styles/images/symbols-sls-solid.png';
import shortDot from '../../styles/images/symbols-sls-short-dot.png';
import shortDashDotDot from '../../styles/images/symbols-sls-short-dash-dot-dot.png';
import shortDashDot from '../../styles/images/symbols-sls-short-dash-dot.png';
import shortDash from '../../styles/images/symbols-sls-short-dash.png';
import dash from '../../styles/images/symbols-sls-dash.png';
import dashDot from '../../styles/images/symbols-sls-dash-dot.png';
import { AddGAEvent } from './GoogleAnalytics';

const Container = styled(Card)`
	overflow: auto;
	padding: 0.5rem;
`;

const ToolContainer = styled.div`
	background: #fff;
	top: 15px;
	right: 15px;
	padding: 10px;
	margin: 0 auto;
`;

const ToolButton = styled.button`
	font-size: 16px;
	background-color: ${(props) => (props.active ? '#0079c1' : 'transparent')};
	border: 1px solid #d3d3d3;
	color: ${(props) => (props.active ? '#e4e4e4' : '#6e6e6e')};
	height: 32px;
	width: 32px;
	text-align: center;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
	margin-right: 4px;
	&:hover {
		background-color: ${(props) => props.hoverBackground};
		color: ${(props) => props.hoverColor};
	}
`;

// Component
const MarkUpMap = (props) => {
	// const [storedGraphics, setStoredGraphics] = useLocalStorage('graphics', '');

	const LAYER_ID = 'sketchLayer';
	const [editGraphic, setEditGraphic] = useState(null);
	const [sketch, setSketch] = useState(null);
	const [sketchColor, setSketchColor] = useState('#9BC951');
	const [sketchType, setSketchType] = useState('');
	const [addText, setAddText] = useState('');
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const pointStyles = ['circle', 'square', 'x', 'diamond', 'triangle'];
	const [pointStyle, setPointStyle] = useState('circle');
	const lineStyles = [
		{ name: 'dash', img: dash },
		{ name: 'dash-dot', img: dashDot },
		{ name: 'short-dash', img: shortDash },
		{ name: 'short-dash-dot', img: shortDashDot },
		{ name: 'short-dash-dot-dot', img: shortDashDotDot },
		{ name: 'short-dot', img: shortDot },
		{ name: 'solid', img: solid },
	];
	const [lineStyle, setLineStyle] = useState('solid');

	// const saveGraphic = (graphic) => {
	// 	setStoredGraphics({ ...storedGraphics, features: [...(storedGraphics.features || []), graphic.toJSON()] });
	// };

	useEffect(() => {
		if (!props.view) {
			return;
		}

		// if (storedGraphics.features) {
		// 	loadModules(['esri/Graphic']).then(async ([Graphic]) => {
		// 		const layer = await addSketchLayer();
		// 		layer && storedGraphics.features.map((g) => layer.add(Graphic.fromJSON(g)));
		// 	});
		// }

		loadModules(['esri/widgets/Sketch/SketchViewModel', 'esri/Color']).then(async ([SketchViewModel, Color]) => {
			const graphicsLayer = await addSketchLayer();

			const sketchViewModel =
				sketch === null
					? new SketchViewModel({
							view: props.view,
							layer: graphicsLayer,
					  })
					: sketch;

			const c = Color.fromHex(sketchColor);
			setSketchSymbology(sketchViewModel, c);

			setUpClickHandler();

			sketchViewModel.on('create', function (event) {
				if (event.state === 'complete') {
					var text = event.graphic.symbol.text;
					// get the centroid, or any relevant point from the drawn graphic
					var centroid;
					var g = event.graphic.geometry;
					if (event.tool === 'point') {
						centroid = { x: g.x, y: g.y };
					} else if (['polygon', 'rectangle', 'circle'].includes(event.tool)) {
						centroid = g.centroid;
					} else if (event.tool === 'polyline') {
						centroid = g.extent.center;
					}

					// add the event. handle text differently so we can add what the text was
					if (centroid) {
						if (text) {
							AddGAEvent('Mark Up Map', `Draw ${event.tool}`, `${centroid.x} ${centroid.y} | ${text}`);
						} else {
							AddGAEvent('Mark Up Map', `Draw ${event.tool}`, `${centroid.x} ${centroid.y}`);
						}
					}
				}
				// allows us to repeatedly create features without clicking the tool buttons
				// making the assumption labels won't be repeatedly created
				if (event.state === 'complete' && sketchType !== 'text') {
					this.create(event.tool);
				}
			});

			// set up logic to handle geometry update and reflect the update on "graphicsLayer"
			function setUpClickHandler() {
				props.view.on('click', function (event) {
					props.view.hitTest(event).then(function (response) {
						var results = response.results;
						if (results.length > 0) {
							for (var i = 0; i < results.length; i++) {
								// Check if we're already editing a graphic
								if (!editGraphic && results[i].graphic.layer.id === 'tempGraphics') {
									// Save a reference to the graphic we intend to update
									const e = results[i].graphic;

									// Remove the graphic from the GraphicsLayer
									// Sketch will handle displaying the graphic while being updated
									graphicsLayer.remove(e);
									sketchViewModel.update(e);
									setEditGraphic(e);
									break;
								}
							}
						}
					});
				});
			}

			setSketch(sketchViewModel);
		});
	}, [props.view]);

	useEffect(() => {
		// for disabling sketching when parent accordion is collapsed
		if (!props.enableDraw) {
			sketch && sketch.cancel();
			setSketchType('');
		}
	}, [props.enableDraw]);

	useEffect(() => {
		if (sketch === null) {
			return;
		}

		loadModules(['esri/Color']).then(async ([Color]) => {
			const c = Color.fromHex(sketchColor);
			setSketchSymbology(sketch, c);

			sketch.cancel();
			sketch.create(sketchType === 'text' ? 'point' : sketchType);
		});
	}, [sketchColor, lineStyle, pointStyle, sketchType]);

	const setSketchSymbology = (vm, color) => {
		const textSymbol = {
			type: 'text', // autocasts as new TextSymbol()
			color: 'white',
			haloColor: color,
			haloSize: '1px',
			text: addText,
			font: {
				// autocasts as new Font()
				size: 18,
				family: 'Montserrat',
				weight: 'bold',
			},
		};
		const pointSymbol = {
			type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
			style: pointStyle,
			color,
			size: 8,
			outline: {
				// autocasts as new SimpleLineSymbol()
				color,
				width: 2,
			},
		};

		vm.pointSymbol = sketchType === 'text' ? textSymbol : pointSymbol;
		vm.polylineSymbol = {
			type: 'simple-line', // autocasts as new SimpleLineSymbol()
			color,
			width: '3',
			style: lineStyle,
		};
		vm.polygonSymbol = {
			type: 'simple-fill', // autocasts as new SimpleFillSymbol()
			color: { ...color, a: 0.6 },
			style: 'solid',
			outline: {
				color: 'white',
				width: 1,
			},
		};
	};

	const addSketchLayer = async () => {
		const GraphicsLayer = (await loadModules(['esri/layers/GraphicsLayer']))[0];

		if (props.view.map.findLayerById(LAYER_ID) == null) {
			const layer = new GraphicsLayer({ id: LAYER_ID, title: LAYER_ID });
			props.view.map.add(layer);
			return layer;
		} else {
			return props.view.map.findLayerById(LAYER_ID);
		}
	};

	const onClearClick = () => {
		AddGAEvent('Mark Up Map', 'Clear Graphics');
		sketch && sketch.cancel();
		clearGraphicsLayer(props.view, LAYER_ID);
		//setStoredGraphics({ ...storedGraphics, features: [] });
		setSketchType('');
		setShowConfirmDelete(false);
	};

	const handleToolClick = (geomType) => {
		if (!sketch) {
			return;
		}

		// use if statement so tool buttons can be toggled
		// do this since we allow multiple features to be added
		if (sketchType === geomType || geomType === '') {
			sketch.cancel();
			setSketchType('');
		} else {
			geomType !== 'text' && sketch.create(geomType);
			setSketchType(geomType);
		}
	};

	const handleTextClick = () => {
		if (sketch === null) {
			return;
		}

		loadModules(['esri/Color']).then(async ([Color]) => {
			const c = Color.fromHex(sketchColor);
			setSketchSymbology(sketch, c);

			sketch.cancel();
			sketch.create(sketchType === 'text' ? 'point' : sketchType);
		});
	};

	if (sketch === null) {
		return null;
	}

	// Component template
	return (
		<Container>
			<Form>
				{sketchType === '' ? (
					<FormControl style={{ marginBottom: 12 }}>
						<FormControlLabel>
							<Alert blue full>
								Further define and label unique locations or points of interest by using the graphic
								icons below. Drop down lists with options for color and style are available for several
								of the graphic types.
							</Alert>
						</FormControlLabel>
					</FormControl>
				) : (
					<Alert yellow full style={{ marginBottom: 8 }}>
						Single click to add points to the graphic. Double-click to complete the graphic.
					</Alert>
				)}

				<ToolContainer>
					<ToolButton
						className="esri-icon-cursor"
						id="cursorButton"
						active={sketchType === ''}
						onClick={() => handleToolClick('')}
						title="Select Features"
						type="button"
					></ToolButton>
					<ToolButton
						className="esri-icon-blank-map-pin"
						id="pointButton"
						active={sketchType === 'point'}
						onClick={() => handleToolClick('point')}
						title="Draw point"
						type="button"
					></ToolButton>
					<ToolButton
						className="esri-icon-polyline"
						id="polylineButton"
						active={sketchType === 'polyline'}
						onClick={() => handleToolClick('polyline')}
						title="Draw polyline"
						type="button"
					></ToolButton>
					<ToolButton
						className="esri-icon-polygon"
						id="polygonButton"
						active={sketchType === 'polygon'}
						onClick={() => handleToolClick('polygon')}
						title="Draw polygon"
						type="button"
					></ToolButton>
					<ToolButton
						className="esri-icon-checkbox-unchecked"
						id="rectangleButton"
						active={sketchType === 'rectangle'}
						onClick={() => handleToolClick('rectangle')}
						title="Draw rectangle"
						type="button"
					></ToolButton>
					<ToolButton
						className="esri-icon-radio-unchecked"
						id="circleButton"
						active={sketchType === 'circle'}
						onClick={() => handleToolClick('circle')}
						title="Draw circle"
						type="button"
					></ToolButton>
					<ToolButton
						className="esri-icon-comment"
						id="textButton"
						active={sketchType === 'text'}
						onClick={() => handleToolClick('text')}
						title="Draw text"
						type="button"
					></ToolButton>
					<ToolButton
						className="esri-icon-trash"
						id="resetBtn"
						active={false}
						onClick={() => setShowConfirmDelete(true)}
						title="Delete ALL graphics"
						type="button"
						hoverBackground="rgb(216, 48, 32)"
						hoverColor="white"
					></ToolButton>
				</ToolContainer>
				{showConfirmDelete && (
					<Alert red title="Delete All Graphics?">
						Are you sure?{' '}
						<Button red onClick={onClearClick}>
							Yes
						</Button>{' '}
						<Button clearGray onClick={() => setShowConfirmDelete(false)}>
							No
						</Button>
					</Alert>
				)}
				<FormControl horizontal style={{ margin: 'auto' }}>
					<Select
						selectedValue={sketchColor}
						onChange={(e) => {
							AddGAEvent('Mark Up Map', 'Change Color', e);
							setSketchColor(e);
						}}
						positionFixed={true}
						style={{ marginRight: 4 }}
					>
						{['#ffffff', '#000000', '#9BC951', '#349BCF'].map((c) => {
							return (
								<MenuItem key={c} value={c}>
									<div
										style={{
											backgroundColor: c,
											width: 20,
											height: '75%',
											border: 'solid 1px #eee',
										}}
									>
										&nbsp;
									</div>
								</MenuItem>
							);
						})}
					</Select>

					{sketchType === 'polyline' && (
						<Select
							selectedValue={lineStyle}
							onChange={(e) => {
								AddGAEvent('Mark Up Map', 'Change Line Style', e);
								setLineStyle(e);
							}}
							positionFixed={true}
						>
							{lineStyles.map((style) => {
								return (
									<MenuItem key={style.name} value={style.name}>
										<img src={style.img} alt={style.name} style={{ width: 125 }} />
									</MenuItem>
								);
							})}
						</Select>
					)}
					{sketchType === 'point' && (
						<Select
							selectedValue={pointStyle}
							onChange={(e) => {
								AddGAEvent('Mark Up Map', 'Change Point Style', e);
								setPointStyle(e);
							}}
							positionFixed={true}
						>
							{pointStyles.map((style) => {
								return (
									<MenuItem key={style} value={style}>
										{style}
									</MenuItem>
								);
							})}
						</Select>
					)}
					{sketchType === 'text' && (
						<>
							<TextField
								placeholder="Enter text"
								value={addText}
								onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
								onChange={(e) => setAddText(e.target.value)}
							/>
							<Button clear onClick={handleTextClick}>
								Add Text
							</Button>
						</>
					)}
				</FormControl>
			</Form>
		</Container>
	);
};

export default MarkUpMap;
