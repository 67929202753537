// React imports
import React, { useEffect, useState } from 'react';
import { loadModules } from 'esri-loader';
import Progress from 'calcite-react/Progress';
import { CalciteH2 } from 'calcite-react/Elements';

// Component
export const Loading = (props) => {
	const [showLoading, setShowLoading] = useState(false);

	useEffect(() => {
		let timeout = null;
		if (props.view) {
			loadModules(['esri/core/watchUtils']).then(([watchUtils]) => {
				watchUtils.whenTrue(props.view, 'updating', function (evt) {
					timeout = setTimeout(() => {
						setShowLoading(true);
					}, 750);
				});

				// Hide the loading indicator when the view stops updating
				watchUtils.whenFalse(props.view, 'updating', function (evt) {
					if (timeout) {
						clearTimeout(timeout);
					}
					setShowLoading(false);
				});
			});
		}
	}, []); //only fire when showLegend changes

	if (showLoading) {
		return (
			<div
				style={{
					width: '100%',
					height: '100%',
					background: 'rgba(255, 255, 255, .6)',
					position: 'absolute',
					zIndex: 5000,
					padding: 8,
					paddingTop: 32,
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				<CalciteH2 style={{ margin: 10 }}>Loading</CalciteH2>

				<Progress type="indeterminate" />
			</div>
		);
	}

	return null;
};
