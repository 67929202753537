// React
import React, { useState, useEffect } from 'react';
import { clearGraphicsLayer, getAcreage } from '../../utils/map';
import { useLocalStorage } from '../../utils/storage';

import Alert, { AlertTitle } from 'calcite-react/Alert';
import { CalciteP } from 'calcite-react/Elements';

import Card from 'calcite-react/Card';
import Form, { FormControl, FormControlLabel, Fieldset } from 'calcite-react/Form';
import Select from 'calcite-react/Select';
import { MenuItem } from 'calcite-react/Menu';

// Styled & Motion Components
import styled from 'styled-components';
import { loadModules } from 'esri-loader';
import { AddGAEvent } from '../nri/GoogleAnalytics';
import { DrawBoundary } from '../nri/DrawBoundary';
import { UploadBoundary } from '../nri/UploadBoundary';
import { StyledButton } from '../StyledButton';

const Container = styled(Card)`
	overflow: auto;
	padding: 0.5rem;
`;

const DefineBoundary = (props) => {
	const [showBoundaryAlert, setShowBoundaryAlert] = useState(false);
	const [selectedColor, setSelectedColor] = useState('#00ffff');
	const [storedGraphics, setStoredGraphics] = useLocalStorage('graphics', '');
	const [error, setError] = useState('');

	const [activeButton, setActiveButton] = useState('');
	const inactive = { color: '#517e45', border: '1px solid #517e45', margin: 'auto', width: '150px' };
	var active = {
		color: '#ffffff',
		border: '1px solid #ffffff',
		backgroundColor: '#517e45',
		margin: 'auto',
		width: '150px',
	};

	useEffect(() => {
		if (storedGraphics.boundary && props.view) {
			loadModules(['esri/Graphic']).then(([Graphic]) =>
				addBoundaryGraphic(Graphic.fromJSON(storedGraphics.boundary))
			);
		}
	}, [props.view]);

	const getBoundaryAlert = () => {
		const element = (
			<FormControl>
				<Fieldset name="boundary" style={{ width: '100%' }}>
					<Alert red full style={{ width: '100%' }}>
						<AlertTitle>Boundary Too Large</AlertTitle>
						Please try again
					</Alert>
				</Fieldset>
			</FormControl>
		);
		return element;
	};

	const addBoundaryGraphic = (g) => {
		setError('');

		//check if within max boundary size
		clearGraphicsLayer(props.view, 'tempGraphic');
		checkBoundarySize(g).then((valid) => {
			if (valid) {
				setShowBoundaryAlert(false);
				addBoundary(props.view, g);

				setActiveButton('');
				props.onUpdate(g.geometry);

				setStoredGraphics({ ...storedGraphics, boundary: g.toJSON() });
			} else {
				//message user need smaller boundary
				setShowBoundaryAlert(true);
				onClearClick();
			}
		});
	};

	const onClearClick = () => {
		clearGraphicsLayer(props.view, 'boundaryLayer');
		clearGraphicsLayer(props.view, 'tempGraphic');
		setActiveButton('');

		props.onUpdate(null);

		setStoredGraphics({ ...storedGraphics, boundary: null });
	};

	const onColorChange = (evt, index) => {
		AddGAEvent('Boundary', 'Change Color', evt);
		setSelectedColor(evt);
	};

	const addBoundary = (view, graphic) => {
		return loadModules(['esri/layers/GraphicsLayer'], {
			css: true,
		}).then(([GraphicsLayer]) => {
			var layer = view.map.findLayerById('boundaryLayer');
			if (!layer) {
				layer = new GraphicsLayer({ id: 'boundaryLayer', title: 'boundaryLayer' });
				view.map.add(layer);
			}
			layer.graphics = [graphic];
		});
	};

	const checkBoundarySize = (boundary) => {
		return getAcreage(boundary.geometry, false).then((area) => {
			return props.maxReportAcres > area;
		});
	};

	return (
		<Container>
			<Form>
				{activeButton !== 'draw' ? (
					<FormControl>
						<FormControlLabel>
							<Alert blue full>
								<CalciteP style={{ marginBottom: 0, fontSize: 'small' }}>
									Delineate an area of interest by drawing a boundary using the webtool or uploading a
									pre-created boundary (zipped polygon shapefiles only). Selections may be cleared
									using the Remove Boundary button.
								</CalciteP>
								<CalciteP style={{ marginBottom: 0, fontSize: 'small' }}>
									<b>Note:</b> You must create a boundary in order to export a report at Step 5.
								</CalciteP>
							</Alert>
						</FormControlLabel>
					</FormControl>
				) : (
					<Alert yellow full style={{ marginBottom: 8 }}>
						<CalciteP style={{ marginBottom: 0, fontSize: 'small' }}>
							Single click to add points to the boundary. Double-click to complete boundary.
						</CalciteP>
					</Alert>
				)}
				<FormControl horizontal style={{ margin: 'auto' }}>
					<Select
						disabled={activeButton === 'draw'}
						half
						selectedValue={selectedColor}
						onChange={onColorChange}
						style={{ margin: '5px' }}
					>
						<MenuItem value="#00ffff">Cyan</MenuItem>
						<MenuItem value="#ffff00">Yellow</MenuItem>
						<MenuItem value="#606060">Dark Gray</MenuItem>
					</Select>
				</FormControl>
				<FormControl horizontal>
					<DrawBoundary
						view={props.view}
						color={selectedColor}
						buttonStyle={activeButton === 'draw' ? active : inactive}
						onDrawStart={() => {
							onClearClick();
							setActiveButton('draw');
						}}
						onDrawEnd={(g) => addBoundaryGraphic(g)}
					/>
				</FormControl>
			</Form>
			{showBoundaryAlert && getBoundaryAlert()}
			<UploadBoundary
				view={props.view}
				color={selectedColor}
				buttonStyle={activeButton === 'upload' ? active : inactive}
				onStart={() => {
					onClearClick();
					setActiveButton('upload');
				}}
				onUpload={(g) => {
					addBoundaryGraphic(g);
					setError('');
				}}
				onError={(e) => setError(e)}
				disabled={activeButton === 'draw'}
			/>
			<Form>
				<FormControl horizontal style={{ marginTop: '15px' }}>
					<StyledButton
						half
						red
						onClick={() => {
							AddGAEvent('Boundary', 'Clear Boundary');
							onClearClick();
						}}
						style={activeButton === 'clear' ? active : inactive}
						disabled={activeButton === 'draw'}
					>
						Remove Boundary
					</StyledButton>
				</FormControl>
			</Form>
			{error !== '' && (
				<Alert red full style={{ marginBottom: 8 }}>
					<CalciteP style={{ marginBottom: 0, fontSize: 'small' }}>{error}</CalciteP>
				</Alert>
			)}
		</Container>
	);
};

export default DefineBoundary;
