import { loadModules } from 'esri-loader';

export function toggleLayer(view, name, visible) {
	return loadModules(['esri/layers/Layer'], {
		css: true,
	}).then(([Layer]) => {
		var layer = view.map.findLayerById(name);
		if (layer) {
			layer.visible = visible;
		} else {
			//try looking for title
			for (var i = 0; i < view.map.layers.length; i++) {
				var l = view.map.layers.items[i];
				if (l.title === name) {
					layer = l;
					break;
				}
			}
			layer.visible = visible;
		}
	});
}

export function clearGraphicsLayer(view, name) {
	return loadModules(['esri/layers/GraphicsLayer']).then(([GraphicsLayer]) => {
		let layer = view.map.findLayerById(name);
		if (layer) {
			layer.removeAll();
		} else {
			//try looking for title
			layer = view.map.layers.find((l) => l.title === name);
			if (!layer) {
				layer = new GraphicsLayer({ id: name, title: name });
				view.map.add(layer);
			} else {
				layer.removeAll();
			}
		}
	});
}

// returns the acreage of the supplied geometry. Will return the value if formatted = false, otherwise will return the number formatted with 'acres' appended
export function getAcreage(boundary, formatted = true) {
	return loadModules(['esri/geometry/geometryEngineAsync', 'esri/intl']).then(([geometryEngineAsync, intl]) => {
		const area = (boundary) => geometryEngineAsync.geodesicArea(boundary, 'acres');
		return geometryEngineAsync
			.simplify(boundary)
			.then(area)
			.then((acres) =>
				formatted
					? `${intl.formatNumber(acres, { style: 'decimal', maximumFractionDigits: 0 })} acres`
					: Math.round(acres)
			);
	});
}
