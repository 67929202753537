import React, { useState } from 'react';

// Utils
import * as report from '../../utils/report';
import { getAcreage } from '../../utils/map';

// Styled & Motion Components
import styled from 'styled-components';

// Component imports
import Loader from 'calcite-react/Loader';
import Alert, { AlertTitle } from 'calcite-react/Alert';
import Card from 'calcite-react/Card';
import Form, { FormControl, Fieldset } from 'calcite-react/Form';
import Checkbox from 'calcite-react/Checkbox';
import { MenuItem } from 'calcite-react/Menu';
import { CalciteUl, CalciteLi, CalciteP } from 'calcite-react/Elements';
import TextField from 'calcite-react/TextField';
import Select from 'calcite-react/Select';
import Button from 'calcite-react/Button';
import PrintPreviewIcon from 'calcite-ui-icons-react/PrintPreviewIcon';
import PrintPreview from './PreviewReport';
import Tooltip from 'calcite-react/Tooltip';
import InformationIcon from 'calcite-ui-icons-react/InformationIcon';
import { AddGAEvent } from './GoogleAnalytics';

const Container = styled(Card)`
	overflow: auto;
	padding: 0.5rem;
`;
const LoadingContainer = styled.div`
	margin-right: 15px;
`;
const HorizontalSelect = styled(Select)`
	margin-right: 10px;
`;
const CustomFormControl = styled(FormControl)`
	margin: 0 0.3875rem 0.75rem;
`;
// Component
const CreateReport = (props) => {
	const [title, setTitle] = useState('');
	const [subtitle, setSubtitle] = useState('');
	const [useBuffer, setUseBuffer] = useState(false);
	const [buffer, setBuffer] = useState(10);
	const [units, setUnits] = useState('miles');
	const [mapUrl, setMapUrl] = useState('');
	const [sectionUrls, setSectionUrls] = useState([]);
	const [acreage, setAcreage] = useState('0 acres');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const acres = () => {
		if (props.config.boundary) {
			getAcreage(props.config.boundary, false).then((acre) => {
				setAcreage(Math.round(acre));
			});
		}
	};

	const hasDataSelection = () =>
		[...props.config.categories].filter(([key, category]) => category.services.some((s) => s.showInReport)).length >
		0;
	const hasBoundarySelection = () => props.config.boundary != null;
	const isBufferValid = () => useBuffer === false || (useBuffer && buffer !== 0);
	const summary = () => {
		const element = (
			<FormControl>
				<Fieldset name="summary" style={{ width: '100%' }}>
					<Alert red full>
						<AlertTitle>Requirements Summary</AlertTitle>
						<CalciteUl style={{ margin: 0, fontSize: 'small' }}>
							{!hasBoundarySelection() && <CalciteLi>Boundary has not been drawn</CalciteLi>}
							{!hasDataSelection() && (
								<CalciteLi>
									At least one dataset from the 'Select Data' step must have the Report box checked
								</CalciteLi>
							)}
							{!isBufferValid() && <CalciteLi>A buffer distance is required</CalciteLi>}
						</CalciteUl>
					</Alert>
				</Fieldset>
			</FormControl>
		);
		return element;
	};

	const onPreviewClick = () => {
		setLoading(true);
		const hasData = hasDataSelection();

		var e = props.view.extent;

		var allLayers = [...props.config.categories].map((c) => c[1].services).flat();
		var reportLayers = allLayers
			.filter((s) => s.showInReport)
			.map((s) => s.name)
			.join('|');
		var mapLayers = allLayers
			.filter((s) => s.showInMap)
			.map((s) => s.name)
			.join('|');

		var extentWkt = `POLYGON((${e.xmin} ${e.ymax}, ${e.xmin} ${e.ymin}, ${e.xmax} ${e.ymin}, ${e.xmax} ${e.ymax}, ${e.xmin} ${e.ymax}))`;
		var b = props.config.boundary;

		AddGAEvent(
			'Report',
			'Generate Report',
			`{"extent": "${extentWkt}", "boundary": "${b.centroid.x} ${b.centroid.y} | ${acreage}", "reportLayers": "${reportLayers}", "mapLayers": "${mapLayers}"}`
		);

		printFromBrowser(hasData);
	};
	const printFromBrowser = (hasData) => {
		const printWindow = window.open('', 'Print Preview', 'height=800,width=1200,resizable=true');
		printWindow.document.write(`<html><head></head><body class="print-preview">`);
		setStyles(printWindow);
		printWindow.document.write(
			hasData
				? document.getElementById('printLoader').outerHTML
				: document.getElementById('printWarning').outerHTML
		);
		printWindow.document.write('</body></html>');
		printWindow.document.close();
		printWindow.focus();
		if (hasData) {
			report
				.prepareReport({
					...props.config,
					view: props.view,
					buffer: useBuffer ? { distance: buffer, units: units } : false,
				})
				.then(([mapUrl, queryResults, acreage, sections]) => {
					setMapUrl(mapUrl.url);
					setAcreage(acreage);
					setSectionUrls(sections);

					// re-add dynamic styles that don't get rendered until now
					setStyles(printWindow);
				})
				.catch((ex) => {
					setError(
						`An error occurred generating report data or maps. Some maps may not display properly. Error: ${ex}`
					);
				})
				.finally(() => {
					setLoading(false);
					printWindow.document.body.innerHTML = document.getElementById('printPreview').outerHTML;

					const print = printWindow.document.getElementById('printBtn');
					print.onclick = () => {
						AddGAEvent('Report', 'Print Report');
						printWindow.print();
					};

					const top = printWindow.document.getElementById('topBtn');
					top.onclick = () => {
						printWindow.scrollTo(0, 0);
					};
				});
		} else {
			setLoading(false);
		}
	};

	const setStyles = (window) => {
		// copy nodes that are not stylesheets
		[...document.head.children]
			.filter((x) => x.nodeName.toLowerCase() !== 'style')
			.forEach((x) => window.document.head.appendChild(x.cloneNode(true)));

		// copy stylesheets
		[...document.styleSheets]
			.filter((sheet) => sheet.href == null) // not links
			.forEach((sheet) => {
				try {
					const style = document.createElement('style');
					style.innerHTML = [...sheet.cssRules].map((rule) => rule.cssText).join('');
					window.document.head.appendChild(style);
				} catch (e) {
					console.error(e);
				}
			});
	};

	// Component template
	return (
		<Container>
			<Form>
				<CustomFormControl>
					<Alert blue full>
						<CalciteP style={{ marginBottom: 0, fontSize: 'small' }}>
							Export your customized map into a results report. You may specify a custom title and
							subtitle for the report below.
						</CalciteP>
					</Alert>
				</CustomFormControl>
				{!(hasBoundarySelection() && hasDataSelection() && isBufferValid()) ? (
					summary()
				) : (
					<CustomFormControl>
						<Alert yellow full>
							<CalciteP style={{ marginBottom: 0, fontSize: 'small' }}>
								The report will show the current extent of the map, which may omit the drawn boundary or
								other graphics.
							</CalciteP>
						</Alert>
					</CustomFormControl>
				)}

				<CustomFormControl>
					<TextField
						fullWidth
						placeholder="Report Title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</CustomFormControl>
				<CustomFormControl>
					<TextField
						fullWidth
						placeholder="Report Subtitle"
						value={subtitle}
						onChange={(e) => setSubtitle(e.target.value)}
					/>
				</CustomFormControl>
				<CustomFormControl>
					{acres()}
					<CalciteP style={{ margin: 0 }}>Area of boundary: {acreage} ac.</CalciteP>
				</CustomFormControl>
				<CustomFormControl>
					<Checkbox
						labelStyle={{ flexGrow: 1 }}
						checked={useBuffer}
						onChange={(e) => setUseBuffer(e.target.checked)}
					>
						Include Surrounding Areas
						<Tooltip
							title="Includes the area surrounding the given boundary by buffering the distance selected below"
							positionFixed
						>
							<InformationIcon size={16} />
						</Tooltip>
					</Checkbox>
				</CustomFormControl>
				<CustomFormControl horizontal>
					<HorizontalSelect id="buffer-distance" selectedValue={buffer} onChange={(e) => setBuffer(e)}>
						<MenuItem value={10}>10</MenuItem>
						<MenuItem value={20}>20</MenuItem>
						<MenuItem value={30}>30</MenuItem>
					</HorizontalSelect>
					<HorizontalSelect id="buffer-unit" selectedValue={units} onChange={(e) => setUnits(e)}>
						<MenuItem value="meters">Meters</MenuItem>
						<MenuItem value="feet">Feet</MenuItem>
						<MenuItem value="kilometers">Kilometers</MenuItem>
						<MenuItem value="miles">Miles</MenuItem>
						<MenuItem value="yards">Yards</MenuItem>
					</HorizontalSelect>
				</CustomFormControl>
				<CustomFormControl>
					<Button
						disabled={!(hasBoundarySelection() && hasDataSelection() && isBufferValid())}
						onClick={onPreviewClick}
						icon={<PrintPreviewIcon />}
						fullWidth
					>
						{loading ? (
							<LoadingContainer>
								<Loader sizeRatio={0.4} color="#fff" />
							</LoadingContainer>
						) : (
							'Generate Report'
						)}
					</Button>
				</CustomFormControl>
				<div style={{ opacity: 0 }}>
					<PrintPreview
						view={props.view}
						config={props.config}
						mapUrl={mapUrl}
						sections={sectionUrls}
						title={title}
						subtitle={subtitle}
						acreage={acreage}
						error={error}
					></PrintPreview>
				</div>
			</Form>
		</Container>
	);
};

export default CreateReport;
