// React imports
import React from 'react';

// Component imports
import logo from '../../styles/images/NRI-white.png';
import Alert, { AlertTitle, AlertMessage } from 'calcite-react/Alert';
import Loader from 'calcite-react/Loader';
import styled from 'styled-components';
import { CalciteH1, CalciteH2, CalciteH3, CalciteH5, CalciteA } from 'calcite-react/Elements';
import ChevronUpIcon from 'calcite-ui-icons-react/ChevronUpIcon';
import Button from 'calcite-react/Button';
import Card, { CardTitle, CardContent, CardImage } from 'calcite-react/Card';
import Table, {
	TableHeader,
	TableHeaderRow,
	TableHeaderCell,
	TableBody,
	TableRow,
	TableCell,
} from 'calcite-react/Table';

const CoverMap = styled(Card)`
	margin: 0 auto 20px auto;
`;
const CardMap = styled(CardImage)`
	img {
		// max-height: 33vh;
	}
`;
// Component
const PrintPreview = (props) => {
	const header = 'TxMAP';

	const format = (value) => {
		const match = value.match(/<link>(.*?)<\/link>/gi);
		if (match) {
			return [
				...match.map((link) => {
					const output = [value.substring(0, value.indexOf(link)), createLink(link)];
					value = value.replace(`${output[0]}${link}`, '');
					return output;
				}),
			].flat();
		} else {
			return [value];
		}
	};
	const createLink = (url) => {
		url = url.replace('<link>', '').replace('</link>', '');
		return (
			<a href={url} target="_blank" rel="noopener noreferrer">
				{url}
			</a>
		);
	};
	const flatten = (category) => {
		let flat = props.config.categories
			.get(category)
			.services.filter((s) => s.showInReport)
			.map((s) => {
				return (s.visibleLayers || [])
					.map((l) => {
						return (l.reportOptions || [])
							.map((opt) => {
								return (opt.value || []).map((v, i) => {
									//check if data is null or blank
									if (!v || v.length === 0) v = 'No Data';

									return {
										key: `${s.name}_${l.name}_${opt.fields.join('_')}_${i}`,
										layer: l.name,
										name: opt.alias,
										value: v,
									};
								});
							})
							.flat();
					})
					.flat();
			})
			.flat();

		if (flat.length === 0) {
			flat = [{ key: 'No Data', layer: 'No Data', name: 'No Data', value: 'No Data' }];
		}
		return flat;
	};
	const Header = (opt) => {
		return (
			<header>
				<img href="#" src={logo} alt="logo" />
				<CalciteH3>{header}</CalciteH3>
				{opt && <CalciteA id="printBtn">Print</CalciteA>}
			</header>
		);
	};
	const sectionUrl = (key) => {
		const active = props.sections.filter((section) => section.key === key);
		if (active && active.length > 0) {
			return active[0].url;
		} else {
			return props.mapUrl;
		}
	};
	// Component template
	return (
		<div>
			<Loader text="Preparing report..." id="printLoader"></Loader>
			<section id="printWarning">
				<Alert yellow full>
					<AlertMessage>
						There are no datasets selected for display in the report. Go to the Select Data section to
						modify your selection.
					</AlertMessage>
				</Alert>
			</section>
			<section id="sectionMaps">
				<div id="sectionDiv" style={{ position: 'fixed', zIndex: 1, width: '100px', height: '100px' }}></div>
			</section>

			<div id="printPreview">
				{props.error !== '' && (
					<Alert red>
						<AlertMessage>{props.error}</AlertMessage>
					</Alert>
				)}
				<Button id="topBtn" icon={<ChevronUpIcon />}>
					Top
				</Button>
				<section>
					{Header(true)}
					<small>Report created {new Date().toLocaleDateString()}</small>
					<CalciteH1>{props.title}</CalciteH1>
					<CalciteH2>{props.subtitle}</CalciteH2>
					<CoverMap className="content">
						<img src={props.mapUrl} alt="Overview map" />
					</CoverMap>
					<div className="disclaimer">
						<CalciteH5>Disclaimer</CalciteH5>
						<p>{props.config.disclaimer}</p>
					</div>
				</section>

				{[...props.config.categories]
					.filter(([key, category]) => category.services.some((s) => s.showInReport))
					.map(([key, category]) => {
						return (
							<section key={key}>
								<header className="justified">
									<CalciteH3>{key}</CalciteH3>
									<CalciteH3>{props.title}</CalciteH3>
									<CalciteH3>{props.acreage}</CalciteH3>
								</header>
								<Card className="content" key={key}>
									<Alert full>
										<AlertTitle>{key} Dataset Description</AlertTitle>
										<AlertMessage>{category.description}</AlertMessage>
									</Alert>
								</Card>
								<Card>
									<CardMap src={sectionUrl(key)} />
									<CardContent>
										<CardTitle>{key}</CardTitle>
										<Table blue className="content">
											<TableHeader>
												<TableHeaderRow>
													<TableHeaderCell>Dataset</TableHeaderCell>
													<TableHeaderCell>Value</TableHeaderCell>
												</TableHeaderRow>
											</TableHeader>
											<TableBody>
												{flatten(key).map((s) => {
													return (
														<TableRow key={`${s.key}`}>
															<TableCell style={{ width: '150px' }}>{s.layer}</TableCell>
															<TableCell>
																{/<link>(.*?)<\/link>/gi.test(s.value)
																	? format(s.value).map((x, i) => {
																			return <span key={i}>{x}</span>;
																	  })
																	: s.value}
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</CardContent>
								</Card>
							</section>
						);
					})}
			</div>
		</div>
	);
};

export default PrintPreview;
