// Copyright 2019 Esri
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// React imports
import React, { useEffect } from 'react';
import { Route, Redirect, BrowserRouter } from 'react-router-dom';
import { setDefaultOptions } from 'esri-loader';

// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { fetchConfig } from '../redux/reducers/config';

// Component imports
import LoadScreen from './LoadScreen';
import Main from './Main';
import { GoogleAnalytics } from './nri/GoogleAnalytics';

// Component definition
const App = (props) => {
	// need to specify 4.18 so basemaps requiring and API key will work
	setDefaultOptions({ version: '4.18' });

	// redux store state
	const config = useSelector((state) => state.config);
	const dispatch = useDispatch();

	// when the component mounts request the config and load it into the Redux state
	useEffect(() => {
		dispatch(fetchConfig());
	}, [dispatch]);

	// RENDER RETURN
	// app is initializing for the following reasons, show the load screen
	// 1. config is not yet loaded
	if (!config.loaded) {
		return <LoadScreen isLoaded={false} />;
	}

	return (
		<BrowserRouter>
			<Route path="/" component={Main} />
			<Redirect to="/" />
			<GoogleAnalytics />
		</BrowserRouter>
	);
};

export default App;
