// React
import React, { useState } from 'react';
import AreaOfInterest from './AreaOfInterest';
import DefineBoundary from './DefineBoundary';
import SelectData from './SelectData';
import MarkUpMap from './MarkUpMap';
import CreateReport from './CreateReport';
import { toggleLayer } from '../../utils/map';

// Components
import Accordion, { AccordionSection, AccordionTitle, AccordionContent } from 'calcite-react/Accordion';
import { CalciteP } from 'calcite-react/Elements';

// Styled & Motion Components
import styled from 'styled-components';
import { StepIcon } from './StepIcon';

const Container = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 400px;
	overflow: auto;
	background-color: #fff;
`;

const AccTitle = styled(AccordionTitle)`
	font-weight: 600 !important;
	&:hover {
		color: white !important;
		background-color: #484a51 !important;
	}
`;

const AccordionContainer = (props) => {
	const [activeSectionIndex, setActiveSectionIndex] = useState(0);
	const [categories, setCategories] = useState(
		new Map([
			...props.categories.map((c) => {
				return [
					c.name,
					{
						name: c.name,
						description: c.description,
						services: c.services.map((s) => ({ ...s, showInMap: s.defaultVisible, showInReport: false })),
					},
				];
			}),
		])
	);
	const [boundary, setBoundary] = useState();

	const handleBoundaryUpdate = (geometry) => {
		setBoundary(geometry);
	};

	const onAccordionChange = (evt, index) => {
		// go to a new section, or set to -1 to close all sections if opened section was clicked
		setActiveSectionIndex((i) => (i !== index ? index : -1));
	};

	const updateCategory = ({ name, category }) => {
		categories.set(name, category);
		const map = new Map([...categories]);
		setCategories(map);
	};

	const handleCuratedMapChange = (selectedMap) => {
		categories.forEach((c) => {
			c.services.forEach((s) => {
				const included = s.curatedMaps?.includes(selectedMap);
				s.showInReport = included;
				s.showInMap = included;
				// toggle visibility in map
				toggleLayer(props.view, s.name, s.showInMap);
			});
		});
		const map = new Map([...categories]);
		setCategories(map);
	};

	return (
		<Container style={{ display: 'flex', flexDirection: 'column' }}>
			<Accordion
				iconPosition="start"
				onAccordionChange={onAccordionChange}
				activeSectionIndexes={[activeSectionIndex]}
				style={{ flexGrow: 1 }}
			>
				<AccordionSection>
					<AccTitle>
						<StepIcon text="1" /> FIND AREA OF INTEREST
					</AccTitle>
					<AccordionContent>
						<AreaOfInterest url={props.countyUrl} fieldName={props.countyField} view={props.view} />
					</AccordionContent>
				</AccordionSection>
				<AccordionSection>
					<AccTitle>
						<StepIcon text="2" /> DEFINE BOUNDARY
					</AccTitle>
					<AccordionContent>
						<DefineBoundary
							view={props.view}
							onUpdate={handleBoundaryUpdate}
							maxReportAcres={props.maxReportAcres}
						/>
					</AccordionContent>
				</AccordionSection>
				<AccordionSection>
					<AccTitle>
						<StepIcon text="3" /> MARKUP MAP
					</AccTitle>
					<AccordionContent>
						{/* Not ideal, but the best way I could think of to disable the draw widget when this section is collapsed */}
						<MarkUpMap enableDraw={activeSectionIndex === 2} view={props.view} />
					</AccordionContent>
				</AccordionSection>
				<AccordionSection>
					<AccTitle>
						<StepIcon text="4" /> SELECT DATA
					</AccTitle>
					<AccordionContent>
						<SelectData
							view={props.view}
							categories={categories}
							onUpdate={updateCategory}
							curatedMaps={props.curatedMaps}
							onCuratedMapChange={handleCuratedMapChange}
						/>
					</AccordionContent>
				</AccordionSection>
				<AccordionSection>
					<AccTitle>
						<StepIcon text="5" /> CREATE REPORT
					</AccTitle>
					<AccordionContent>
						<CreateReport
							view={props.view}
							config={{
								printServerURL: props.printServerURL,
								disclaimer: props.disclaimer,
								categories,
								boundary,
							}}
						/>
					</AccordionContent>
				</AccordionSection>
			</Accordion>
			<CalciteP style={{ margin: 4, fontSize: 'small' }}>
				Need help? View the{' '}
				<a href="TxMAP Tool User Guide.pdf" target="_blank" rel="noopener noreferrer">
					user guide
				</a>{' '}
				or <a href="mailto:nri-gis@ag.tamu.edu?subject=TxMap Help">contact us</a>
			</CalciteP>
		</Container>
	);
};

export default AccordionContainer;
