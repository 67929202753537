// React imports
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// ESRI ArcGIS API
import { loadModules } from 'esri-loader';

// Styled Components
import styled from 'styled-components';
import { AddGAEvent } from '../../nri/GoogleAnalytics';

const Container = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
`;

// Component
const Map = (props) => {
	// set an ID for the map to attach to
	const dispatch = useDispatch();

	const containerID = 'map-view-container';
	const [isMapLoading, setIsMapLoading] = useState(false);

	if (!isMapLoading && !props.isMapLoaded) {
		setIsMapLoading(true);
		loadModules(
			[
				'esri/config',
				'esri/Map',
				'esri/views/MapView',
				'esri/layers/Layer',
				'esri/widgets/Search',
				'esri/widgets/ScaleBar',
			],
			{
				css: true,
			}
		)
			.then(([esriConfig, Map, MapView, Layer, Search, ScaleBar]) => {
				const mapOptions = props.mapConfig;
				const categories = props.categories;
				if (!containerID) {
					return;
				}
				esriConfig.apiKey =
					'AAPKa06dd5fb6cc04f5bac26e734fe2b692c00wJoqG_yIcbxnmyMaBpF7_jvJxQfHw7qwelZLwBEoekl5yDT7qOOAYHYa0l0-v3';
				esriConfig.request.timeout = 180000;

				// create the Map
				const map = new Map({ basemap: mapOptions.basemap });
				let view = new MapView({
					map,
					constraints: {
						rotationEnabled: false,
					},
					container: containerID,
					...mapOptions,
				});
				// wait for the view to load
				return view.when(() => {
					const searchWidget = new Search({
						view: view,
						container: document.getElementById('searchDiv'),
					});

					searchWidget.on('search-complete', function (event) {
						// The results are stored in the event Object[]
						AddGAEvent('Area of Interest', 'Go to Location', event.searchTerm);
					});

					// hack to prevent button clicks from refreshing page
					// it's an issue with the search widget and esri claims other widgets
					// were fixed at 4.17 https://community.esri.com/t5/arcgis-api-for-javascript/print-export-widget-bug-button-clicking-causes-postback-in-asp/td-p/212015
					// making the assumption a user won't type in a search query and click a button within 2 seconds
					setTimeout(function () {
						var submitBtn = document.getElementsByClassName('esri-search__submit-button')[0];
						var clearBtn = document.getElementsByClassName('esri-search__clear-button')[0];
						if (submitBtn) {
							submitBtn.setAttribute('type', 'button');
						}
						if (clearBtn) {
							clearBtn.setAttribute('type', 'button');
						}
					}, 2000);

					const scaleBar = new ScaleBar({
						view: view,
						unit: 'dual', // The scale bar displays both metric and non-metric units.
					});

					// Add the widget to the bottom left corner of the this.view
					view.ui.add(scaleBar, {
						position: 'bottom-left',
					});

					//add layers
					// reverse layers and sublayers so drawing order matches left nav order
					if (categories) {
						for (var i = 0; i < categories.length; i++) {
							var category = categories[i];

							for (var j = 0; j < category.services.length; j++) {
								var service = category.services[j];
								var sublayers = [];
								for (var s = 0; s < service.visibleLayers.length; s++) {
									var visible = service.visibleLayers[s];
									var defaultPopup = {
										title: visible.name,
										content: visible.reportOptions[0].format,
									};

									sublayers.push({
										id: visible.id,
										visible: true,
										title: visible.name,
										popupTemplate:
											visible.disablePopup === true
												? null
												: { ...defaultPopup, ...visible.popup },
									});
								}
								Layer.fromArcGISServerUrl({ url:service.url, properties:{
									id: service.name,
									title: service.alias,
									url: service.url,
									sublayers: sublayers.reverse(),
									visible: service.defaultVisible
								}}).then(function(layer){map.add(layer);
								});
							}
						}
						map.layers.reverse();
					}

					// return a reference to the view
					return view;
				});
			})
			.then((view) => {
				// call the map loaded event when we get the map view back
				dispatch(props.onMapLoaded(true));
				props.setMapView(view);
			});
	}
	// Compnent template
	return <Container id={containerID}>{props.children}</Container>;
};

export default Map;
