import React from 'react';
import styled from 'styled-components';

const CircleText = styled.span`
	border: solid 2px #aaa;
	padding: 0px 9px;
	border-radius: 16px;
	margin-right: 8px;
	color: #aaa;
`;

export const StepIcon = (props) => {
	return <CircleText>{props.text}</CircleText>;
};
