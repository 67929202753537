// React imports
import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

// Component imports

// Component
export const Home = (props) => {
	useEffect(() => {
		if (props.view) {
			loadModules(['esri/widgets/Home']).then(([Home]) => {
				var homeWidget = new Home({
					view: props.view,
				});

				props.view.ui.add(homeWidget, 'top-left');
			});
		}
	}, []); //only fire when showLegend changes

	return null;
};
