/** @module utils/report */
import { queryFeatures } from '@esri/arcgis-rest-feature-layer';
import { loadModules } from 'esri-loader';

const FEATURE_LIMIT = 10;
const prepareReport = async ({ view, printServerURL, categories, boundary, buffer }) => {
	const existing = view.graphics.find((x) => x.attributes && x.attributes['id'] === 'report_buffer');
	existing && view.graphics.remove(existing);

	//need to create a map view for each section
	return await getSectionViews(categories, view).then((maps) => {
		return getBuffer({ boundary, buffer }).then((boundary) => {
			//first base map view
			let printmap = getPrintMap(buffer, boundary, view, printServerURL);
			//now sections
			let sections = [];
			maps.forEach((sectionMap) => {
				if (sectionMap.view !== null) {
					let print = getPrintMap(buffer, boundary, sectionMap.view, printServerURL, sectionMap.key);
					sections.push(print);
				}
			});
			let printsections = Promise.all(sections).then((results) => {
				return results;
			});

			return Promise.all([
				printmap,
				getFeatures({ view, categories, boundary }),
				getAcreage({ boundary }),
				printsections,
			]);
		});
	});
};

const esriGeometryType = (type) =>
	type === 'point'
		? 'esriGeometryPoint'
		: type === 'multipoint'
		? 'esriGeometryMultipoint'
		: type === 'polyline'
		? 'esriGeometryPolyline'
		: type === 'polygon'
		? 'esriGeometryPolygon'
		: 'esriGeometryEnvelope';

const getSectionViews = async (categories, view) => {
	let views = [];
	const l = view.map.findLayerById('boundaryLayer');
	for (let [key, value] of categories) {
		const currentConfig = {
			basemap: view.map.basemap.id,
			extent: view.extent,
			scale: view.scale,
			boundary: l.graphics.items[0],
		};

		var v = loadPrintMap(currentConfig, value, key);
		views.push(v);
	}

	//now add layers for each category
	return await Promise.all(views).then((values) => {
		return values;
	});
};

const getPrintMap = (buffer, boundary, view, printServerURL, key) => {
	let printmap =
		buffer && buffer.distance && buffer.distance > 0
			? loadModules(['esri/Graphic']).then(([Graphic]) => {
					view.graphics.add(
						new Graphic({
							geometry: boundary,
							symbol: {
								type: 'simple-fill',
								color: [0, 0, 0, 0],
								outline: {
									color: [255, 0, 0],
									width: 2,
								},
							},
							attributes: {
								id: 'report_buffer',
							},
						})
					);
					return getPrintUrl({ view, printServerURL, key });
			  })
			: getPrintUrl({ view, printServerURL, key });

	return printmap;
};

export function loadPrintMap(mapOptions, category, key) {
	return loadModules(['esri/Map', 'esri/views/MapView', 'esri/layers/Layer', 'esri/Graphic'], {
		css: true,
	}).then(([Map, MapView, Layer, Graphic]) => {
		var map = new Map({
			basemap: mapOptions.basemap,
		});
		//add layers
		let layerCnt = 0;

		if (category) {
			for (var j = 0; j < category.services.length; j++) {
				var sublayers = [];
				var service = category.services[j];
				if (service.showInMap === true) {
					for (var s = 0; s < service.visibleLayers.length; s++) {
						var visible = service.visibleLayers[s];
						sublayers.push({ id: visible.id, visible: visible.defaultVisible, title: visible.name });
					}
					Layer.fromArcGISServerUrl(service.url, {
						id: service.name,
						title: service.alias,
						url: service.url,
						sublayers: sublayers.reverse(),
						visible: service.defaultVisible
					}).then(function(layer){map.add(layer);
					});
					layerCnt++;
				}
			}
			map.layers.reverse();
		}

		let view2 = new MapView({
			map: map,
			container: 'sectionDiv',
			extent: mapOptions.extent,
		});
		view2
			.when(() => {
				if (view2) {
					view2.extent = mapOptions.extent;
					view2.scale = mapOptions.scale;
					let graphic = new Graphic({
						geometry: mapOptions.boundary.geometry,
						symbol: mapOptions.boundary.symbol,
					});
					view2.graphics.add(graphic);
				}
			})
			.catch((error) => {
				console.error('error');
			});
		if (layerCnt === 0) {
			view2 = null;
		}
		return { view: view2, key: key };
	});
}

const getPrintUrl = ({ view, printServerURL, key }) => {
	if (printServerURL === '') {
		return { url: '', key: key };
	}

	return loadModules([
		'esri/tasks/PrintTask',
		'esri/tasks/support/PrintParameters',
		'esri/tasks/support/PrintTemplate',
	]).then(([PrintTask, PrintParameters, PrintTemplate]) => {
		const print = new PrintTask(
			{ url: `${printServerURL.replace(/\/+$/g, '')}/Export%20Web%20Map` },
			{
				timeout: 180000,
			}
		);

		const printParameters = new PrintParameters({
			view: view,
			template: new PrintTemplate({
				format: 'jpg',
				layout: key !== undefined ? 'TxMAP_LegendOnlyPrint' : 'MAP_ONLY', // do map only for first page overview map
				exportOptions: {
					height: 800,
					width: 1100,
					dpi: 96,
				},
				// esri docs lied. it said not setting legend layers would show all operational layers
				// but that was not the case. Explicitly adding them so they'll show for each section of the report
				layoutOptions: {
					legendLayers: view.map.layers.map((l) => ({ title: l.title, layerId: l.id })),
				},
			}),
		});

		return print.execute(printParameters).then((result) => {
			return { url: result.url, key: key };
		});
	});
};

const getFeatures = ({ view, categories, boundary }) => {
	const features = new Map(
		[...categories]
			.filter(([name, category]) => category.services.some((s) => s.showInReport === true))
			.map(([name, category]) => {
				return [
					name,
					{
						...category,
						services: category.services
							.filter((s) => s.showInReport === true)
							.map((service) => {
								(service.visibleLayers || []).forEach((layer) => {
									const params = {
										url: `${service.url.replace(/\/+$/g, '')}/${layer.id}`,
										returnGeometry: false,
										geometry: boundary.toJSON(),
										geometryType: esriGeometryType(boundary.type),
										httpMethod: 'POST',
									};

									// string fields will be treated as a single query to return distinct values
									const queries = getStringQueries({ params, layer, service });

									// OLD assumption that report fields would be summary statistics (leaving it as future option)
									// all other summary field types can be included in a single query with multiple statistic definitions
									//queries.push(getStatisticsQuery({ params, layer, service }));

									layer.queries = queries.filter((q) => !!q);
								});
								return { ...service };
							}),
					},
				];
			})
	);

	const all = [...features]
		.map(([name, category]) =>
			category.services
				.map((s) => {
					return [...s.visibleLayers.map((l) => l.queries).flat()];
				})
				.flat()
		)
		.flat();

	return Promise.all(all).then((results) => {
		return features;
	});
};
// calculates acreage from the supplied geometry
const getAcreage = ({ boundary }) => {
	return loadModules(['esri/geometry/geometryEngineAsync', 'esri/intl']).then(([geometryEngineAsync, intl]) => {
		return geometryEngineAsync
			.simplify(boundary)
			.then((boundary) => geometryEngineAsync.geodesicArea(boundary, 'acres'))
			.then((acres) => `${intl.formatNumber(acres, { style: 'decimal', maximumFractionDigits: 0 })} acres`);
	});
};

const getBuffer = ({ boundary, buffer }) => {
	return loadModules(['esri/geometry/geometryEngineAsync']).then(([geometryEngineAsync]) => {
		return buffer && buffer.distance && buffer.distance > 0
			? geometryEngineAsync.buffer(boundary, buffer.distance, buffer.units)
			: Promise.resolve(boundary);
	});
};

const getStringQueries = ({ params, layer, service }) => {
	//remove previous results
	layer.reportOptions.forEach((opt) => {
		if (opt.hasOwnProperty('value')) {
			delete opt.value;
		}
	});

	return layer.reportOptions
		.filter((opt) => opt.format && opt.format.length)
		.map((opt) => {
			return queryFeatures({ ...params, ...{ returnDistinctValues: true, outFields: opt.fields } })
				.then((result) => {
					if (result.features.length > 0) {
						formatResultSet(opt, result);
					}
				})
				.catch((ex) =>
					console.error(`An error occurred while querying ${service.url.replace(/\/+$/g, '')}/${layer.id}
      from '${layer.name}': ${ex}`)
				);
		});
};

const aggregateValues = (aggField, aggFunc, features) => {
	switch (aggFunc) {
		case 'count':
			return features.length;
		case 'distinct':
			return [...new Set(features.map((x) => x.attributes[aggField]))].join(', ');
		case 'range':
			var items = [...new Set(features.map((x) => x.attributes[aggField].replace(/,/g, '')))];
			// only do range for numbers
			if (items.some(isNaN)) {
				throw new Error('The range aggregate function can only be performed with numbers');
			}
			if (!items.length) {
				return '';
			}
			var min = Math.min(...items).toLocaleString();
			var max = Math.max(...items).toLocaleString();
			// show range. only show a single value if they're the same
			return min === max ? min : min + '-' + max;
		default:
			return '';
	}
};

const formatResultSet = (option, result) => {
	return loadModules(['esri/intl']).then(([intl]) => {
		// format the output result set
		const format = option.format || '';

		// aggregate functions
		const aggKeyMatches = format.match(/{([a-zA-Z0-9_.-]*):(key)}/) || [];
		const aggFieldAndFunc = format.match(/{([a-zA-Z0-9_.-]*):(count|distinct|range)}/) || [];
		if (aggKeyMatches.length || aggFieldAndFunc.length) {
			// the format as it would be without any aggregate key or function modifiers
			const deAggedFormat = format.replace(/:(key|count|distinct|range)/g, '');

			// should return three elements: the whole key including curly breaces, just the field name, and just 'key'
			if (aggKeyMatches.length > 3) {
				throw new Error('only one format key can be specified');
			}
			if (aggKeyMatches.length > 0 && aggFieldAndFunc.length === 0) {
				throw new Error(
					'one aggregate function must be specified if a key is specified for ' + aggKeyMatches[1]
				);
			}

			// get the actual field name from regex matches
			const aggKey = aggKeyMatches[1] || '';
			const aggField = aggFieldAndFunc[1];
			const aggFunc = aggFieldAndFunc[2];

			// get distinct key values
			const keyValues = [...new Set(result.features.map((x) => x.attributes[aggKey]))];

			// show values in table based on singleLine
			if (option.singleLine === true || aggKey === '') {
				const rowValue = {
					[aggKey || 'aggKey']: keyValues.join(', '),
					[aggField]: aggregateValues(aggField, aggFunc, result.features),
				};
				// set default values so fields that aren't part of the key or agg function still get incorporated
				let defaultValues = {};
				option.fields.map((f) => (defaultValues[f] = aggregateValues(f, 'distinct', result.features)));

				// combines values for all keys
				option.value = [
					rowValue === {} ? 'No Data' : intl.substitute(deAggedFormat, { ...defaultValues, ...rowValue }, {}),
				];
			} else {
				// get associated aggregate value for each key
				const rowValues = keyValues.map((k) => ({
					[aggKey]: k,
					[aggField]: aggregateValues(
						aggField,
						aggFunc,
						result.features.filter((f) => f.attributes[aggKey] === k)
					),
				}));

				// separates values for each key
				option.value = rowValues.map((v) => intl.substitute(deAggedFormat, v, {}));
			}
		} else if (
			(option.fields.length === 1 && result.features.length > FEATURE_LIMIT) ||
			option.singleLine === true
		) {
			// an object that contains field names and associated string-joined values
			let rowValues = {};

			// join field values into a single string that will be lump substituted in the field's format
			option.fields.map((f) => (rowValues[f] = result.features.map((x) => x.attributes[f]).join(', ')));
			option.value = [rowValues === {} ? 'No Data' : intl.substitute(format, rowValues, {})];
		} else {
			// just map the features (one per table row) and substitute all fields
			option.value = result.features.map((x) =>
				!x.attributes || x.attributes.length === 0 ? 'No Data' : intl.substitute(format, x.attributes, {})
			);
		}

		return result;
	});
};

export {
	/** Creates the HTML markup to display the report.
	 * @returns {Promise} A promise that will resolve to a DOM element containing the fully populated markup.
	 */
	prepareReport,
	/** Get features from the service based on current extent and where clause.
	 * @returns {Promise} A promise that will resolve to an array of features.
	 */
	getFeatures,
	/** Get map export from print service.
	 * @returns {Promise} A promise that will resolve to a base64 string containing the map image output.
	 */
	getPrintUrl,
};
