// React imports
import React, { useEffect, useState } from 'react';
import { toggleLayer } from '../../utils/map';

// Component imports
import List, { ListItem, ListItemTitle } from 'calcite-react/List';
import { Fieldset } from 'calcite-react/Form';
import Tooltip from 'calcite-react/Tooltip';
import Checkbox from 'calcite-react/Checkbox';

import ChevronUpIcon from 'calcite-ui-icons-react/ChevronUpIcon';
import ChevronDownIcon from 'calcite-ui-icons-react/ChevronDownIcon';
import LayerBasemapIcon from 'calcite-ui-icons-react/LayerBasemapIcon';
import InformationIcon from 'calcite-ui-icons-react/InformationIcon';

const Category = (props) => {
	const [services, setServices] = useState(props.services);

	useEffect(() => {
		setServices(props.services.map((s) => ({ ...s, showInMap: s.defaultVisible, showInReport: false })));
	}, []);

	useEffect(() => {
		props.onUpdate({
			name: props.name,
			category: {
				name: props.name,
				description: props.description,
				services: services,
			},
		});
	}, [services]);

	const [reportChecked, setReportChecked] = useState(false);
	const [mapChecked, setMapChecked] = useState(false);

	const [open, setOpen] = useState(false);
	const [arrowIcon, setArrowIcon] = useState(<ChevronUpIcon style={{ width: '24px', height: '24px' }} />);

	const toggleSublist = () => {
		setOpen(!open);

		let icon;
		if (open) {
			icon = <ChevronUpIcon style={{ width: '24px', height: '24px' }} />;
		} else {
			icon = <ChevronDownIcon style={{ width: '24px', height: '24px' }} />;
		}
		setArrowIcon(icon);
	};

	const toggleMapVisibility = (event) => {
		//need a list of turned on layers for custom report
		var id = event.currentTarget.id;
		if (id.length > 0) {
			const service = services.find((x) => x.name === id.replace('_map', ''));
			if (service) {
				service.showInMap = !service.showInMap;
			}
			setServices([...services]);

			// toggle visibility
			toggleLayer(props.view, id.replace('_map', ''), service.showInMap);
		}
	};
	const toggleReportVisibility = function (event) {
		var id = event.currentTarget.id;
		if (id.length > 0) {
			const service = services.find((x) => x.name === id.replace('_report', ''));
			if (service) {
				service.showInReport = !service.showInReport;
			}
			setServices([...services]);
		}
	};

	const toggleAllMapVisibility = (event) => {
		event.stopPropagation();
		var checked = event.currentTarget.checked;

		setServices(services.map((s) => ({ ...s, showInMap: checked })));

		// update visibility in map
		services.map((s) => toggleLayer(props.view, s.name, checked));
		setMapChecked(checked);
	};

	const toggleAllReportVisibility = (event) => {
		event.stopPropagation();
		var checked = event.currentTarget.checked;

		setServices(services.map((s) => ({ ...s, showInReport: checked })));

		setReportChecked(checked);
	};

	// Component template
	return (
		<List style={{}} key={props.name}>
			<ListItem leftNode={<LayerBasemapIcon />} rightNode={arrowIcon} onClick={toggleSublist}>
				<ListItemTitle style={{ fontWeight: 'bold' }}>{props.name}</ListItemTitle>
				<Fieldset horizontal name="options">
					<Checkbox
						id={`${props.name}_report`}
						checked={reportChecked}
						onChange={toggleAllReportVisibility}
						onClick={(e) => e.stopPropagation()}
					>
						Report
					</Checkbox>
					<Checkbox
						id={`${props.name}_map`}
						checked={mapChecked}
						onChange={toggleAllMapVisibility}
						onClick={(e) => e.stopPropagation()}
					>
						Map
					</Checkbox>
				</Fieldset>
			</ListItem>
			<List multiSelect nested open={open} style={{ paddingLeft: '.5rem', maxHeight: open ? 9999 : 0 }}>
				{services.map((service) => {
					return (
						<ListItem
							rightNode={
								<Tooltip title={service.layerDescription} positionFixed>
									<InformationIcon size={16} />
								</Tooltip>
							}
							key={service.name}
						>
							<ListItemTitle style={{ textAlign: 'left' }}>{service.name}</ListItemTitle>
							<Fieldset horizontal name="options">
								<Checkbox
									id={`${service.name}_report`}
									checked={service.showInReport}
									onChange={toggleReportVisibility}
								>
									Report
								</Checkbox>
								<Checkbox
									id={`${service.name}_map`}
									checked={service.showInMap}
									onChange={toggleMapVisibility}
								>
									Map
								</Checkbox>
							</Fieldset>
						</ListItem>
					);
				})}
			</List>
		</List>
	);
};

export default Category;
