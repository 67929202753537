import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/** Tracks router history and sends an event when history changes */
export const HistoryTracker = (props) => {
	// https://stackoverflow.com/a/57356519
	useEffect(
		() =>
			props.history.listen(() => {
				props.onChange(window.location.pathname + window.location.search);
			}),
		[]
	);

	return null;
};

export default withRouter(HistoryTracker);
