import React from 'react';
import Modal from 'calcite-react/Modal';
import Button from 'calcite-react/Button';
import { CalciteP } from 'calcite-react/Elements';
import InformationIcon from 'calcite-ui-icons-react/InformationIcon';
import { useSessionStorage } from '../../utils/storage';

export const SplashModal = (props) => {
	const element = document.getElementById('modal');
	const [splashViewed, setSplashViewed] = useSessionStorage('splashViewed', false);

	const handleClose = () => {
		setSplashViewed(true);
	};

	return (
		<Modal
			open={!splashViewed}
			overlayStyle={{ position: 'absolute' }}
			dialogStyle={{ width: '60%' }}
			appElement={element}
			title="Texas A&M Natural Resources Institute TxMAP"
			onRequestClose={handleClose}
			actions={[
				<Button key="save" onClick={handleClose}>
					Ok
				</Button>,
			]}
		>
			<CalciteP>
				Welcome to TxMAP, a mapping tool developed by the{' '}
				<a href="https://nri.tamu.edu" target="_blank" rel="noopener noreferrer">
					Texas A&amp;M Natural Resources Institute
				</a>
				.
			</CalciteP>
			<CalciteP>
				TxMAP provides Texas stakeholders with information to better understand and visualize natural
				resources across the state through the detailed curation of multiple statewide datasets that represent our
				program areas of Wildlife Conservation and Mitigation, Land Trends and Demography, Military Land
				Sustainability, and Private Land Stewardship. The tool allows users to customize the interactive map by
				defining areas of interest to perform an intersect query on selected geospatial data layers. Results can
				be printed directly, or exported as an informative report.
			</CalciteP>
			<CalciteP>
				We have provided information boxes <InformationIcon size={16} /> throughout the tool to help guide you
				through each step. You can also view our{' '}
				<a href="TxMAP Tool User Guide.pdf" target="_blank" rel="noopener noreferrer">
					user guide
				</a>{' '}
				for step-by-step instructions. Still need help? Feel free to reach out to our helpdesk at{' '}
				<a href="mailto:nri-gis@ag.tamu.edu">nri-gis@ag.tamu.edu</a>.
			</CalciteP>
		</Modal>
	);
};
