// React imports
import React, { useState } from 'react';
import Category from './Category';

// Component imports
import Card from 'calcite-react/Card';
import Select from 'calcite-react/Select';
import { MenuItem } from 'calcite-react/Menu';
import Form, { FormControl, FormControlLabel } from 'calcite-react/Form';

import Tooltip from 'calcite-react/Tooltip';
import InformationIcon from 'calcite-ui-icons-react/InformationIcon';
import List, { ListItem, ListItemTitle } from 'calcite-react/List';

// Styled & Motion Components
import styled from 'styled-components';

const Container = styled(Card)`
	overflow: auto;
	padding: 0.5rem;
`;

// Component
const SelectData = (props) => {
	const [selectedMap, setSelectedMap] = useState('');

	const onCuratedMapChange = (evt) => {
		setSelectedMap(evt);
		props.onCuratedMapChange && props.onCuratedMapChange(evt);
	};

	const [showSelectDataTip, setShowSelectDataTip] = useState(true);
	const handleShowTip = () => {
		setShowSelectDataTip(!showSelectDataTip);
	};
	const selectDataTooltip = () => {
		return (
			<>
				<p>
					Data layers are grouped by major categories, including general land and water features and NRI's key
					program areas. Each layer group can be expanded to view individual data layers by selecting the down
					arrow next to the group name. Users can select Report and/or Map for individual data layers or for
					the layer group as a whole. The Map function will add the data layer to the map view. The Report
					function will include the data layer information in the results report.
				</p>
				<p align="left">
					<i>Notes:</i>
				</p>
				<i>
					<ul align="left">
						<li>
							Information about data layers can be viewed by clicking the information icon{' '}
							<InformationIcon size={16} /> next to the layer name.
						</li>
						<li>
							You must have at least one data layer selected for Report in order to export a results
							report.
						</li>
						<li>
							Some data layers may not appear to draw on the map because they are county-level datasets
							(such as the Private Land Stewardship layers).
						</li>
						<li>Clicking on the map will provide "pop-up" information about visible data layers.</li>
					</ul>
				</i>
			</>
		);
	};

	// Component template
	return (
		<Container>
			<List>
				<ListItem onClick={handleShowTip} key="info">
					<ListItemTitle>Show\Hide Instructions</ListItemTitle>
				</ListItem>
			</List>
			<div id="SelectData">
				{props.curatedMaps.length > 0 && (
					<Form>
						<FormControl>
							<FormControlLabel>Curated Data</FormControlLabel>
							<Select selectedValue={selectedMap} onChange={onCuratedMapChange} fullWidth>
								{props.curatedMaps.map((data) => {
									return (
										<MenuItem key={data} value={data}>
											{data}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Form>
				)}
				{/* https://stackoverflow.com/a/48278856 */}
				{[...props.categories].map(([key, value]) => {
					return (
						<Category
							view={props.view}
							key={value.name}
							name={value.name}
							description={value.description}
							services={value.services}
							onUpdate={props.onUpdate}
						/>
					);
				})}
			</div>
		</Container>
	);
};

export default SelectData;
