import React, { useState } from 'react';
import { loadModules } from 'esri-loader';
import ComboButton from 'calcite-react/ComboButton';
import Menu, { MenuItem } from 'calcite-react/Menu';
import { ToastTitle, ToastMessage, notify } from 'calcite-react/Toaster';
import PrintIcon from 'calcite-ui-icons-react/PrintIcon';
import { AddGAEvent } from '../../nri/GoogleAnalytics';

export const PrintMap = (props) => {
	const [isPrinting, setIsPrinting] = useState(false);
	const [previousPrints, setPreviousPrints] = useState([]);

	const printEntireMap = () => {
		return loadModules([
			'esri/tasks/PrintTask',
			'esri/tasks/support/PrintParameters',
			'esri/tasks/support/PrintTemplate',
		]).then(([PrintTask, PrintParameters, PrintTemplate]) => {
			const print = new PrintTask(
					{ url: `${props.printServerURL.replace(/\/+$/g, '')}/Export%20Web%20Map` },
					{
						timeout: 180000,
					}
				),
				printParameters = new PrintParameters({
					view: props.mapView,
					template: new PrintTemplate({
						format: 'pdf',
						layout: 'TxMAP_Print',
						layoutOptions: {
							titleText: 'TxMap',
							authorText: 'Texas A&M NRI',
						},
						exportOptions: {
							dpi: 300,
						},
						scalePreserved: false,
					}),
				});

			return print.execute(printParameters).then((result) => {
				return result.url;
			});
		});
	};

	const handlePrintClick = () => {
		var e = props.mapView.extent;
		var extentWkt = `POLYGON((${e.xmin} ${e.ymax}, ${e.xmin} ${e.ymin}, ${e.xmax} ${e.ymin}, ${e.xmax} ${e.ymax}, ${e.xmin} ${e.ymax}))`;

		var mapLayers = props.mapView.map.layers.items
			.filter((l) => l.visible && !['sketchLayer', 'boundaryLayer', 'tempGraphic'].includes(l.id))
			.map((s) => s.title)
			.join('|');

		AddGAEvent('General', 'Print Map', `{"extent": "${extentWkt}", "mapLayers": "${mapLayers}"}`);
		setIsPrinting(true);

		printEntireMap()
			.then(handlePrintSuccess)
			.finally(() => setIsPrinting(false));
	};

	const handlePrintSuccess = (url) => {
		setPreviousPrints([...previousPrints, { url, timestamp: new Date() }]);
		notify(
			<React.Fragment>
				<ToastTitle>Map Printed</ToastTitle>
				<ToastMessage>A link to view/download will be in the Print Map dropdown</ToastMessage>
			</React.Fragment>
		);
	};

	return (
		<ComboButton
			clear
			onClick={handlePrintClick}
			icon={<PrintIcon />}
			disabled={isPrinting}
			label={isPrinting ? 'Printing...' : 'Print Map'}
		>
			<Menu>
				{previousPrints.length ? (
					previousPrints.map((p) => {
						return (
							<MenuItem key={p.url} onClick={() => window.open(p.url, '_blank')}>
								{p.timestamp.toLocaleString()}
							</MenuItem>
						);
					})
				) : (
					<MenuItem>You have not printed any maps yet</MenuItem>
				)}
			</Menu>
		</ComboButton>
	);
};
