// React imports
import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

// Component
export const Basemap = (props) => {
	useEffect(() => {
		if (props.view) {
			loadModules(
				[
					'esri/widgets/BasemapGallery',
					'esri/widgets/BasemapGallery/support/PortalBasemapsSource',
					'esri/widgets/Expand',
				],
				{
					css: true,
				}
			).then(([BasemapGallery, PortalBasemapsSource, Expand]) => {
				const source = new PortalBasemapsSource({
					// filtering portal basemaps
					filterFunction: (basemap) => props.allowedBasemapTitles.indexOf(basemap.portalItem.title) > -1,
				});

				const basemapGallery = new BasemapGallery({
					view: props.view,
					container: document.getElementById('basemap'),
					source: source,
				});

				var bgExpand = new Expand({
					view: props.view,
					content: basemapGallery,
					autoCollapse: true,
				});

				props.view.ui.add(bgExpand, 'top-right');
			});
		}
	}, []);

	return null;
};
