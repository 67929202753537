// Copyright 2019 Esri
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

import { createGlobalStyle } from 'styled-components';

// Global page styling
// override AGIS JS API Widgets here
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    font-family: Montserrat, sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 16px;
  }

  .esri-layer-list__item{
    &::before {
      width: 100%;
      left: 0;
    }
  }
  #basemap {
    max-width: initial;
  }
  
  @media print {
	body { -webkit-print-color-adjust: exact; color-adjust: exact; }
    @page {
      size: letter;  // US 8.5in x 11in
      margin: .5in;
    }
		#printBtn,
		#topBtn {
      display: none;
    }       
    footer,
    .disclaimer
    {
      bottom: 0;
    }
    footer {
      position: fixed;
    }
    .disclaimer {
      position: fixed;      
    }
  }
  #printPreview,
  #printLoader,
  #printError,
  #printWarning {
    display: none;
  }

  .print-preview {    
    #printPreview,
    #printLoader {
      display: block;
    }
    #printError,
    #printWarning {
      display: flex;
      margin: 20px;
      justify-content: center;
    }
  }
  #printPreview,
  #printLoader,
  #printError,
  #printWarning {
    display: none;
  }

  .print-preview {    
    #printPreview,
    #printLoader {
      display: block;
    }
    #printError,
    #printWarning {
      display: flex;
      margin: 20px;
      justify-content: center;
    }
  }
  
  #printPreview {      
    width: 1050px;
    height: auto;
    margin: 0 auto;

    section {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      .content {
        padding: 1rem;
        margin-bottom: 20px;
      }
      &:not(:first-child) {
        break-before: page;      
      }
      > small {
        align-self: flex-end;
      }  
    }    
    header {
      display: flex;
      padding: 10px;
      align-items: center;      
      background-color: #517e45;
	  color: #fff;      
      box-shadow: 0 0 0 1px rgba(0,0,0,0.1), rgba(0,0,0,0.15) 0 0 16px 0;
      h3 {
        flex-grow: 1;
        margin: 5px 5px 5px 20px;
      }
      img {
        height: 55px;
      }
      &.justified {
        justify-content: space-between;
        h3 {
          margin: 5px;
          flex-basis: 33.33vw;
          &:nth-child(2) {
            text-align: center;
          }
          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }  
     
    footer,
    .disclaimer {
      p {
        padding: 5px;
        margin: 0;
        border: solid 1px #999;
      }
    }       
    h1 {
      margin: 1rem 0 2rem 0;
      text-align: center;
      font-size: 3rem;
      line-height: 100%;
    }
	h2 {
		text-align: center;
	}
    h5 {
      margin-bottom: 5px;
    }
  }
  #printLoader {
    margin: 20px;    
  }
  #topBtn {
    position: fixed;
    height: 45px;
    top: 0px;
    right: 0;    
  }
  #printBtn {
    text-align: right;
    color: #fff;
  }
`;
