import React, { useState, useEffect } from 'react';
import { AddGAEvent } from '../nri/GoogleAnalytics';
import { StyledButton } from '../StyledButton';
import { loadModules } from 'esri-loader';
import { getAcreage } from '../../utils/map';

export const DrawBoundary = (props) => {
	const [draw, setDraw] = useState(null);
	const drawConnects = [];

	useEffect(() => {
		//need to update draw's action.on to have latest state values
		if (draw !== null) {
			draw.reset();
			onDrawClick();
		}
	}, [props.color]);

	const onDrawClick = async () => {
		props.onDrawStart();
		const Graphic = (await loadModules(['esri/Graphic']))[0];

		if (!draw) {
			initDraw({ view: props.view, drawAction: 'polygon' }).then(({ draw, action }) => {
				setDraw(draw); //sync ref to draw
				props.view.focus();
				drawConnects.push(
					action.on(
						[
							// when user double-clicks on the view or presses the "C" key
							'draw-complete',
						],
						(e) => drawPolygon(e)
					)
				);
				drawConnects.push(action.on('cursor-update', (e) => drawTempGraphic(Graphic, e)));
			});
		} else {
			draw.reset();
			disconnect();
			clearTempGraphic();
		}
	};

	const drawPolygon = async ({ type, vertices }) => {
		const Graphic = (await loadModules(['esri/Graphic']))[0];
		const spatialReference = props.view.spatialReference,
			symbol = {
				type: 'simple-fill',
				color: props.color,
				style: 'solid',
				outline: {
					color: [96, 96, 96],
					width: 4,
				},
			};

		// create a new graphic representing the polygon, add it to the view
		clearTempGraphic();
		const graphic = await createGraphicJson({ type: 'polygon', vertices, spatialReference, symbol });
		if (graphic) {
			graphic.attributes = { id: 'boundary_graphic' };
			const g = new Graphic(graphic);
			g.symbol.color.a = 0.4;
			if (type === 'draw-complete') {
				var centroid = g.geometry.centroid;
				getAcreage(g.geometry, false).then((ac) =>
					AddGAEvent('Boundary', 'Draw Boundary', `${centroid.x} ${centroid.y} | ${ac}`)
				);
				props.onDrawEnd(g);
				setDraw(null);
			}
		}
	};

	async function createGraphicJson({ type, vertices, spatialReference, symbol }) {
		if (!(vertices && vertices.length > 1)) return Promise.resolve();
		//only for polylines and polygons

		const geometry = createGeometry({ vertices, type, spatialReference });
		if (symbol == null) {
			symbol =
				type === 'polyline'
					? {
							type: 'simple-line',
							style: 'solid',
							color: [0, 0, 0],
							width: 2,
					  }
					: {
							type: 'simple-fill',
							color: [0, 0, 0, 0.25],
							style: 'solid',
							outline: {
								color: [255, 0, 0],
								width: 2,
							},
					  };
		}
		return { geometry, symbol };
	}

	const drawTempGraphic = async (Graphic, { vertices }) => {
		if (!(vertices && vertices.length > 1)) return;

		const spatialReference = props.view.spatialReference,
			symbol = {
				type: 'simple-fill',
				color: [0, 0, 0, 0],
				style: 'solid',
				outline: {
					color: props.color,
					width: 4,
				},
			};
		clearTempGraphic();
		const graphic = await createGraphicJson({ type: 'polygon', vertices, spatialReference, symbol });

		if (graphic) {
			graphic.attributes = { id: 'temp_graphic' };
			const g = new Graphic(graphic);

			props.view.graphics.add(g);
		}
	};

	const createGeometry = ({ coordinates, vertices, type, spatialReference }) => {
		if (!(coordinates || (vertices && vertices.length > 1))) return;
		switch (type) {
			case 'point':
				return { type, x: coordinates[0], y: coordinates[1], spatialReference };
			case 'rectangle':
				return {
					type,
					xmin: Math.min(vertices[0][0], vertices[1][0]),
					xmax: Math.max(vertices[0][0], vertices[1][0]),
					ymin: Math.min(vertices[0][1], vertices[1][1]),
					ymax: Math.max(vertices[0][1], vertices[1][1]),
					spatialReference,
				};
			case 'polyline':
				return { type, paths: vertices, spatialReference };
			case 'polygon':
			default:
				return { type, rings: vertices, spatialReference };
		}
	};

	const clearTempGraphic = () => {
		const existing = props.view.graphics.filter((g) => g.attributes && g.attributes['id'] === 'temp_graphic');
		existing && props.view.graphics.removeMany(existing);
	};

	const initDraw = ({ view, drawAction, drawOptions }) => {
		return loadModules(['esri/views/draw/Draw'], { css: true }).then(([Draw]) => {
			const draw = new Draw({ view }),
				action = draw.create(drawAction, drawOptions);
			return { draw, action };
		});
	};

	const disconnect = () => {
		drawConnects.forEach((e) => e && e.remove());
		drawConnects.length = 0; //shouldn't the length be 0 after remove?
	};

	return (
		<StyledButton half clear onClick={onDrawClick} style={props.buttonStyle}>
			{!draw ? 'Draw Boundary' : 'Cancel'}
		</StyledButton>
	);
};
