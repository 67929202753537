// React imports
import React, { useEffect } from 'react';
import { loadModules } from 'esri-loader';
import Panel, { PanelTitle, PanelText } from 'calcite-react/Panel';

// Component
export const Info = (props) => {
	useEffect(() => {
		if (props.view) {
			loadModules(['esri/widgets/Expand']).then(([Expand]) => {
				var bgExpand = new Expand({
					view: props.view,
					content: document.getElementById('info-container'),
					expandIconClass: 'esri-icon-notice-round',
					mode: 'floating',
				});

				props.view.ui.add(bgExpand, 'top-right');
			});
		}
	}, []); //only fire when showLegend changes

	return (
		<Panel id="info-container" white style={{ minWidth: 300 }}>
			<PanelTitle>TxMap Information</PanelTitle>
			<PanelText>
				<p>
					TxMAP provides Texas stakeholders with information needed to better understand and visualize natural
					resources across the state through the detailed curation of multiple statewide datasets, matching
					our program areas of Wildlife Conservation and Mitigation, Land Trends and Demography, Military Land
					Sustainability, and Private Land Stewardship.
				</p>
			</PanelText>
			<PanelText>
				<p>
					The tool allows users to customize the interactive map by defining areas of interest to perform an
					intersect query on selected geospatial data layers. Results can be printed directly, or exported as
					an informative report.
				</p>
			</PanelText>
		</Panel>
	);
};
