import React from 'react';
import EsriAlert, { AlertTitle, AlertMessage } from 'calcite-react/Alert';

// Component
export const Alert = (props) => {
	return (
		<EsriAlert
			{...props}
			style={{
				fontFamily: '"Avenir Next W01", "Avenir Next W00", "Avenir Next", Avenir, "Helvetica Neue", sans-serif',
			}}
		>
			<AlertTitle>{props.title}</AlertTitle>
			<AlertMessage style={{ marginBottom: 0 }}>{props.children}</AlertMessage>
		</EsriAlert>
	);
};
