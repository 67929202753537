import React, { useState, useEffect } from 'react';
import Panel from 'calcite-react/Panel';
import Popover from 'calcite-react/Popover';
import TextField from 'calcite-react/TextField';
import Papa from 'papaparse';
import { getAcreage } from '../../utils/map';
import Form, { FormControl, FormControlLabel, Fieldset } from 'calcite-react/Form';
import { AddGAEvent } from '../nri/GoogleAnalytics';
import { loadModules } from 'esri-loader';
import Button from 'calcite-react/Button';
import Alert, { AlertTitle } from 'calcite-react/Alert';
import { StyledButton } from '../StyledButton';

export const UploadBoundary = (props) => {
	const [showUpload, setShowUpload] = useState(false);
	const [showServiceUrlAlert, setShowServiceUrlAlert] = useState(false);
	const [uploadUrl, setUploadUrl] = useState('');

	useEffect(() => {
		if (uploadUrl === '') {
			setShowServiceUrlAlert(false);
		}
	}, [uploadUrl]);

	// #region service URL
	// shows an error if the supplied URL is not a valid/supported map service
	const getServiceUrlAlert = () => {
		const element = (
			<FormControl>
				<Fieldset name="boundary" style={{ width: '100%' }}>
					<Alert red full style={{ width: '100%' }}>
						<AlertTitle>Service URL Not Supported</AlertTitle>
						Please try again
					</Alert>
				</Fieldset>
			</FormControl>
		);
		return element;
	};

	const getServiceObject = (serviceUrl) => {
		return loadModules(['esri/request'], {
			css: true,
		}).then(([esriRequest]) => {
			return esriRequest(serviceUrl, {
				query: {
					f: 'json',
				},
				responseType: 'json',
			});
		});
	};

	const getType = (serviceUrl) => {
		if (serviceUrl.indexOf('.KML') > -1 || serviceUrl.indexOf('.KMZ') > -1) {
			return 'KML';
		} else if (serviceUrl.indexOf('.CSV') > -1) {
			return 'CSV';
		} else if (serviceUrl.indexOf('.XML') > -1) {
			return 'GEORSS';
		} else if (serviceUrl.indexOf('/') > -1) {
			var parts = serviceUrl.split('/');

			if (parts[parts.length - 1].toUpperCase() === 'MAPSERVER') {
				return 'Map Service';
			} else if (parts[parts.length - 1].toUpperCase() === 'IMAGESERVER') {
				return 'Image Service';
			} else if (parts[parts.length - 1].toUpperCase() === 'FEATURESERVER') {
				return 'Feature Service';
			} else if (
				isNaN(parts[parts.length - 1]) === false &&
				['FEATURESERVER', 'MAPSERVER'].includes(parts[parts.length - 2].toUpperCase())
			) {
				return 'Feature Service';
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	const externalLayerExtractGraphic = (response, serviceType, serviceUrl) => {
		return loadModules(
			[
				'esri/layers/FeatureLayer',
				'esri/layers/KMLLayer',
				'esri/layers/WMSLayer',
				'esri/layers/CSVLayer',
				'esri/geometry/SpatialReference',
			],
			{
				css: true,
			}
		).then(([FeatureLayer, KMLLayer, WMSLayer, CSVLayer, SpatialReference]) => {
			var layerObj = null;

			if (serviceType === 'Web Map') {
				layerObj = new FeatureLayer({
					portalItem: {
						// autocasts as esri/portal/PortalItem
						id: serviceUrl,
					},
				});
			} else if (serviceType === 'Map Service' || serviceType === 'Feature Service') {
				/* else if (serviceType === 'Image Service') {
		  layerObj = new ImageryLayer({
			url: serviceUrl,
			format: "jpgpng" // server exports in either jpg or png format
		  });
		  layerObj.name = layerTitle;
		} */
				layerObj = new FeatureLayer({ url: serviceUrl });
			} else if (serviceType === 'KML') {
				layerObj = new KMLLayer({ url: serviceUrl });
			} else if (serviceType === 'CSV') {
				layerObj = new CSVLayer({ url: serviceUrl });
			} else if (serviceType === 'WMS') {
				layerObj = new WMSLayer({ url: serviceUrl });
			} else {
				//cannot add this item
				// return null to avoid calling .load() on a null object below
				console.warn('cannot add service to map');
				return null;
			}
			return layerObj.load().then((response) => {
				var query = response.createQuery();
				query.outSpatialReference = SpatialReference.WGS84;
				return response
					.queryFeatures(query)
					.then(function (results) {
						if (results.features.length > 0) {
							var graphic = results.features[0];
							return graphic;
						}
					})
					.catch(function (error) {
						console.error(error);
						return null;
					});
			});
		});
	};

	const handleAddLayer = () => {
		getServiceObject(uploadUrl)
			.then((response) => {
				var serviceType = getType(uploadUrl.toUpperCase());
				externalLayerExtractGraphic(response.data, serviceType, uploadUrl).then((graphic) => {
					if (graphic) {
						//get graphic and set symbol
						props.view.goTo(graphic);
						graphic.symbol = {
							type: 'simple-fill',
							color: props.color,
							style: 'solid',
							outline: {
								color: [96, 96, 96],
								width: 4,
							},
						};
						graphic.symbol.color.a = 0.4;
						props.onUpload(graphic);
					} else {
						setShowServiceUrlAlert(true);
					}
				});

				//props.view.map.add(layerObj);
			})
			.catch((err) => {
				console.error(err);
				setShowServiceUrlAlert(true);
				return null;
			});
		setShowUpload(false);
	};
	// #endregion service URL

	const onUploadClick = () => {
		props.onStart();
		setShowUpload(true);
	};

	const handleUploadFile = (file) => {
		let fileName = file;
		if (fileName.indexOf('\\') > -1) {
			//filename is full path in IE so extract the file name
			var arr = fileName.split('\\');
			fileName = arr[arr.length - 1];
		}

		if (
			fileName.toUpperCase().indexOf('.TXT') > -1 ||
			fileName.toUpperCase().indexOf('.CSV') > -1 ||
			fileName.toUpperCase().indexOf('.GPX') > -1
		) {
			var input = document.getElementById('file-content');
			if (input.files && input.files.length === 1) {
				Papa.parse(input.files[0], {
					header: true,
					dynamicTyping: true,
					complete: (results) => {
						var featureCollection = generateFeatureCollectionTemplateCSV(results.data, results.meta.fields);
						let featureLayer = createFeatureLayerTemplate(
							featureCollection.layerDefinition.fields,
							fileName
						);

						featureLayer.defaultPopupTemplateEnabled = true;

						//need to handle columns with mixed data types
						for (var i = 0; i < results.data.length; i++) {
							for (var key in results.data[i]) {
								//find field in feature collection
								for (var j = 0; j < featureLayer.fields.length; j++) {
									var field = featureLayer.fields[j];
									if (
										results.data[i][key] !== undefined &&
										field.type === 'esriFieldTypeString' &&
										field.name === key
									) {
										results.data[i][key] = results.data[i][key].toString();
										break;
									}
								}
							}
						}

						//create source
						featureLayer.source = createFeatures(results.data);
						var g = featureLayer.source.getItemAt(0);
						var centroid = g.geometry.centroid;
						// don't log if it's not a polygon with a centroid. Collecting the event is not worth breaking things
						if (centroid) {
							getAcreage(g.geometry, false).then((ac) =>
								AddGAEvent('Boundary', 'Upload Boundary', `${centroid.x} ${centroid.y} | ${ac}`)
							);
						}
						props.onUpload(g);
						//props.view.map.add(featureLayer);
						setShowUpload(false);
						setUploadUrl('');
					},
					error: function (err, file, inputElem, reason) {
						// executed if an error occurs while loading the file,
						// or if before callback aborted for some reason
						console.error(err);
						setUploadUrl('');
						setShowUpload(false);
					},
				});
			} else {
				//not supporting multiple files
			}
		} else if (fileName.toUpperCase().indexOf('.ZIP') > -1) {
			generateFeatureCollection(
				fileName,
				props.view.spatialReference,
				document.getElementById('uploadForm'),
				props.view
			)
				.then((response) => {
					if (response.error) {
						console.error('RESPONSEERROR');

						return;
					}

					var data = response.data;

					addShapefileToMap(data.featureCollection, props.view)
						.then((g) => {
							g.symbol.color.a = 0.4;

							var centroid = g.geometry.centroid;
							// don't log if it's not a polygon with a centroid. Collecting the event is not worth breaking things
							if (centroid) {
								getAcreage(g.geometry, false).then((ac) =>
									AddGAEvent('Boundary', 'Upload Boundary', `${centroid.x} ${centroid.y} | ${ac}`)
								);
							}

							props.onUpload(g);
						})
						.catch((err) => props.onError(err.message));
					setShowUpload(false);
				})
				.catch((err) => {
					console.warn(err);
					// Handle any error that occurred in any of the previous
					// promises in the chain.
					setShowUpload(false);
					props.onError(err.message);
					return null;
				});
		} else {
			props.onError('Please select a zip, csv, or text file');
		}
	};

	const generateFeatureCollectionTemplateCSV = (results, fields) => {
		//create a feature collection for the input csv file
		var featureCollection = {
			layerDefinition: null,
			featureSet: {
				features: [],
				geometryType: 'esriGeometryPoint',
			},
		};

		var colorVal = Math.round(Math.floor(Math.random() * (255 - 1 + 1) + 1));

		featureCollection.layerDefinition = {
			geometryType: 'esriGeometryPoint',
			objectIdField: '__OBJECTID',
			type: 'Feature Layer',
			typeIdField: '',
			drawingInfo: {
				renderer: {
					type: 'simple',
					symbol: {
						type: 'esriSMS',
						style: 'esriSMSCircle',
						color: [colorVal, 0, 0, 125],
						size: 8,
						angle: 0,
						xoffset: 0,
						yoffset: 0,
						outline: {
							color: [colorVal, 0, 0, 200],
							width: 1,
						},
					},
				},
			},
			fields: [
				{
					name: '__OBJECTID',
					alias: '__OBJECTID',
					type: 'esriFieldTypeOID',
					editable: false,
					domain: null,
				},
			],
			types: [],
			capabilities: 'Query',
		};

		fields.forEach(function (field) {
			var value = results[0][field];
			var parsedValue = Number(value);
			var index = -1;
			if (value) index = value.toString().indexOf('.');
			if (!isNaN(parsedValue) && index > -1) {
				//check first value and see if it is a number
				featureCollection.layerDefinition.fields.push({
					name: field,
					alias: field,
					type: 'esriFieldTypeDouble',
					editable: true,
					domain: null,
				});
			} else {
				featureCollection.layerDefinition.fields.push({
					name: field,
					alias: field,
					type: 'esriFieldTypeString',
					editable: true,
					domain: null,
				});
			}
		});
		return featureCollection;
	};

	const createFeatureLayerTemplate = (fields, fileName) => {
		return loadModules(['esri/layers/FeatureLayer'], {
			css: true,
		}).then(([FeatureLayer]) => {
			var colorVal = Math.round(Math.floor(Math.random() * (255 - 1 + 1) + 1));

			return new FeatureLayer({
				fields: fields,
				geometryType: 'point',
				title: fileName,
				renderer: {
					type: 'simple', // autocasts as new SimpleRenderer()
					symbol: {
						type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
						size: 8,
						color: [colorVal, 0, 0, 125],
						outline: {
							// autocasts as new SimpleLineSymbol()
							width: 1,
							color: [colorVal, 0, 0, 200],
						},
					},
				},
			});
		});
	};

	const addShapefileToMap = (featureCollection, view) => {
		return loadModules(['esri/layers/FeatureLayer', 'esri/Graphic', 'esri/layers/support/Field'], {
			css: true,
		}).then(([FeatureLayer, Graphic, Field]) => {
			// add the shapefile to the map and zoom to the feature collection extent
			// if you want to persist the feature collection when you reload browser, you could store the
			// collection in local storage by serializing the layer using featureLayer.toJson()
			// see the 'Feature Collection in Local Storage' sample for an example of how to work with local storage
			var sourceGraphics = [];

			featureCollection.layers.map(function (layer) {
				var graphics = layer.featureSet.features
					.filter((f) => !f.geometry.rings || f.geometry.rings.length)
					.map(function (feature) {
						var g = Graphic.fromJSON(feature);
						g.symbol = {
							type: 'simple-fill',
							color: props.color,
							style: 'solid',
							outline: {
								color: [96, 96, 96],
								width: 4,
							},
						};
						return g;
					});
				sourceGraphics = sourceGraphics.concat(graphics.filter((g) => g.geometry.type === 'polygon'));
				var featureLayer = new FeatureLayer({
					objectIdField: 'FID',
					source: graphics,
					fields: layer.layerDefinition.fields.map(function (field) {
						return Field.fromJSON(field);
					}),
				});

				featureLayer.defaultPopupTemplateEnabled = true;
				return featureLayer;
				// associate the feature with the popup on click to enable highlight and zoom to
			});
			//view.map.addMany(layers);
			view.goTo(sourceGraphics);

			if (!sourceGraphics.length) {
				throw new Error(
					'No valid features in shapefile. Ensure your shapefile contains a .shp, .shx, .dbf, .prj and contains polygon features.'
				);
			}
			return sourceGraphics[0];
		});
	};

	const createFeatures = (data) => {
		return loadModules(['esri/Graphic', 'esri/geometry/Point', 'esri/geometry/SpatialReference'], {
			css: true,
		}).then(([Graphic, Point, SpatialReference]) => {
			var features = [];

			var colorVal = Math.round(Math.floor(Math.random() * (255 - 1 + 1) + 1));

			var symbol = {
				type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
				style: 'square',
				color: [colorVal, 0, 0, 125],
				size: '8px', // pixels
				outline: {
					// autocasts as new SimpleLineSymbol()
					color: [colorVal, 0, 0, 200],
					width: 1, // points
				},
			};
			var fields = Object.keys(data[0]);
			for (let i = 0; i < fields.length; i++) {
				if (
					fields[i].trim().match(/(^|\W)lon($|\W)|(^|\W)longitude($|\W)|(^|\W)x($|\W)|(^|\W)xcenter($|\W)/i)
				) {
					var xfield = fields[i];
				}
				if (fields[i].trim().match(/(^|\W)lat($|\W)|(^|\W)latitude($|\W)|(^|\W)y($|\W)|(^|\W)ycenter($|\W)/i)) {
					var yfield = fields[i];
				}
			}

			for (var i = 0; i < data.length; i++) {
				let f = data[i];

				var pointESRI = new Point(f[xfield], f[yfield], SpatialReference.WGS84);

				//var canProjectWGS84toWebMercator = WebMercator.canProject(SpatialReference.WGS84, SpatialReference.WebMercator);
				// let projectGeom = WebMercator.geographicToWebMercator(pointESRI);
				const feature = new Graphic({
					geometry: pointESRI,
					symbol: symbol,
					attributes: f,
				});
				features.push(feature);
			}

			return features;
		});
	};

	const generateFeatureCollection = (fileName, spatialReference) => {
		return loadModules(['esri/request'], {
			css: true,
		}).then(([esriRequest]) => {
			var name = fileName.split('.');
			//Chrome and IE add c:\fakepath to the value - we need to remove it
			//See this link for more info: http://davidwalsh.name/fakepath
			name = name[0].replace('c:\\fakepath\\', '');
			//document.getElementById('upload-status').innerHTML = '<b>Loading… </b>' + name;

			var params = {
				name: name,
				targetSR: spatialReference,
				maxRecordCount: 1000,
				enforceInputFileSizeLimit: true,
				enforceOutputJsonSizeLimit: true,
			};

			var f = document.getElementById('file-input');
			var file = f.files[0];
			var formData = new FormData();
			formData.append('file', file);

			var myContent = {
				filetype: 'shapefile',
				publishParameters: JSON.stringify(params),
				f: 'json',
			};

			//use the rest generate operation to generate a feature collection from the zipped shapefile
			return esriRequest('https://gis.nri.tamu.edu/portal/sharing/rest/content/features/generate', {
				body: formData,
				query: myContent,
			});
			// };
		});
	};

	const closePopover = () => {
		setShowUpload(false);
		setUploadUrl('');
	};

	return (
		<>
			{showServiceUrlAlert && getServiceUrlAlert()}
			<Popover
				targetEl={
					<StyledButton
						half
						clear
						onClick={onUploadClick}
						style={props.buttonStyle}
						disabled={props.disabled}
					>
						Upload Boundary
					</StyledButton>
				}
				targetContainerStyles={{ display: 'inline-flex', margin: 'auto' }}
				open={showUpload}
				onRequestClose={closePopover}
			>
				<Panel style={{ backgroundColor: '#666666' }}>
					<Form>
						<FormControl style={{ color: 'white', margin: '0px' }}>
							<FormControlLabel htmlFor="name">
								Enter a URL from ArcGIS Online or Portal hosted data. Or select a local file.
							</FormControlLabel>
						</FormControl>
						<FormControl style={{ margin: '5px' }}>
							<TextField
								half
								value={uploadUrl}
								onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
								onChange={(e) => setUploadUrl(e.target.value)}
								placeholder="Enter URL"
								rightAdornment={<Button onClick={handleAddLayer}>Upload URL</Button>}
							/>
						</FormControl>
					</Form>
					<Form id="uploadForm">
						<FormControl style={{ margin: '5px' }}>
							<input
								type="file"
								id="file-input"
								onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
								onChange={(e) => handleUploadFile(e.target.value)}
								accept=".csv, .json, .txt, .zip"
								style={{ backgroundColor: '#ffffff' }}
							/>
							<div id="file-content"></div>
						</FormControl>
						<FormControl style={{ margin: '5px', float: 'right' }}>
							<Button style={{ color: 'white' }} extraSmall inline onClick={closePopover}>
								Close
							</Button>
						</FormControl>
					</Form>
				</Panel>
			</Popover>
		</>
	);
};
