// React
import React, { useState } from 'react';

import Card from 'calcite-react/Card';
import Form, { FormControl, FormControlLabel } from 'calcite-react/Form';
import Select from 'calcite-react/Select';
import { MenuItem } from 'calcite-react/Menu';
import Alert from 'calcite-react/Alert';
import { CalciteP } from 'calcite-react/Elements';
import { loadModules } from 'esri-loader';

// Styled & Motion Components
import styled from 'styled-components';
import { AddGAEvent } from './GoogleAnalytics';

const Container = styled(Card)`
	overflow: auto;
	padding: 0.5rem;
`;

const AreaOfInterest = (props) => {
	const [isGettingCounties, setIsGettingCounties] = useState(false);
	const [counties, setCounties] = useState([]);
	const [selectedCounty, setSelectedCounty] = useState('');

	const getTexasCounties = (url, fieldName) => {
		return loadModules(['esri/layers/FeatureLayer'], {
			css: true,
		}).then(([FeatureLayer]) => {
			var counties = [];
			const layer = new FeatureLayer({
				url: url,
			});
			const queryParams = layer.createQuery();
			queryParams.returnGeometry = false;
			queryParams.outFields = [fieldName];
			queryParams.orderByFields = [fieldName];
			queryParams.where = '1=1';
			queryParams.returnDistinctValues = true;

			// query the layer with the modified params object
			return layer
				.queryFeatures(queryParams)
				.then(function (results) {
					// prints the array of result graphics to the console

					for (var i = 0; i < results.features.length; i++) {
						var graphic = results.features[i];
						var value = graphic.attributes[fieldName];
						counties.push(value);
					}

					return counties;
				})
				.catch(function (error) {
					console.error(error);
					return [];
				});
		});
	};

	if (!isGettingCounties) {
		getTexasCounties(props.url, props.fieldName).then((countyList) => {
			setCounties(countyList);
		});
		setIsGettingCounties(true);
	}

	const getCountyExtent = (url, fieldName, name) => {
		return loadModules(['esri/layers/FeatureLayer'], {
			css: true,
		}).then(([FeatureLayer]) => {
			var extent;
			const layer = new FeatureLayer({
				url: url,
			});
			const queryParams = layer.createQuery();
			queryParams.outFields = [fieldName];
			queryParams.where = fieldName + " = '" + name + "'";

			// query the layer with the modified params object
			return layer
				.queryFeatures(queryParams)
				.then(function (results) {
					if (results.features.length > 0) {
						var graphic = results.features[0];
						extent = graphic.geometry.extent;
						return extent;
					}
				})
				.catch(function (error) {
					console.error(error);
					return null;
				});
		});
	};

	const onCountyChange = (evt) => {
		AddGAEvent('Area of Interest', 'Select County', evt);
		setSelectedCounty(evt);
		if (evt !== '') {
			getCountyExtent(props.url, props.fieldName, evt).then((extent) => {
				if (extent) {
					//zoom to map
					props.view.goTo({ target: extent });
					props.onSelect && props.onSelect();
				}
			});
		}
	};

	return (
		<Container>
			<Form>
				<FormControl>
					<FormControlLabel>
						<Alert blue full>
							<CalciteP style={{ marginBottom: 0, fontSize: 'small' }}>
								Navigate to an area of interest by entering a known address, place, or coordinate into
								the text box, selecting a county from the dropdown list, or using the zoom keys on the
								screen or the mouse scroll wheel.
							</CalciteP>
						</Alert>
					</FormControlLabel>
				</FormControl>
				<FormControl>
					<div id="searchDiv" style={{ width: '100%', paddingTop: '10px' }}></div>
				</FormControl>
				<FormControl>
					<FormControlLabel>County</FormControlLabel>
					<Select selectedValue={selectedCounty} onChange={onCountyChange} fullWidth>
						{counties.map((county) => {
							return (
								<MenuItem key={county} value={county}>
									{county}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Form>
		</Container>
	);
};

export default AreaOfInterest;
