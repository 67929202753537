// Copyright 2019 Esri
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// React imports
import React, { useState } from 'react';

// Redux imports
import { useSelector } from 'react-redux';
import { mapLoaded } from '../redux/reducers/map';

// Component imports
import Button from 'calcite-react/Button';
import TopNav from 'calcite-react/TopNav';
import TopNavBrand from 'calcite-react/TopNav/TopNavBrand';
import TopNavList from 'calcite-react/TopNav/TopNavList';
import Map from './esri/map/Map';
import { Basemap } from './esri/map/Basemap';
import { Legend } from './esri/map/Legend';
import { Home } from './esri/map/Home';
import { Info } from './esri/map/Info';
import { PrintMap } from './esri/map/PrintMap';
import { Loading } from './esri/map/Loading';
import AccordionContainer from './nri/Accordion';
import LoadScreen from './LoadScreen';
import { SplashModal } from './nri/SplashModal';
// import nriLogo from '../styles/images/NRI-white.png';
import txmapLogo from '../styles/images/TxMAP White.png';
import HelpIcon from 'calcite-ui-icons-react/QuestionIcon';
import { AddGAEvent } from './nri/GoogleAnalytics';

// Styled Components
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	width: 100%;
	height: 100%;
`;

const MapWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	position: relative;
	z-index: 0;
	overflow: hidden;
`;

const Logo = styled(TopNavBrand)`
	justify-content: center;
	& img {
		height: 45px;
	}
	padding-top: 5px;
	padding-bottom: 5px;
`;

const Nav = styled(TopNav)`
	&& {
		z-index: 5;
		background-color: #005175;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
	}
`;

const NavList = styled(TopNavList)`
	text-align: right;
	padding-right: 15px !important;
`;

// Component definition
const Main = () => {
	const [mapView, setMapView] = useState(null);

	const config = useSelector((state) => state.config);
	const isMapLoaded = useSelector((state) => state.map.loaded);

	return (
		<Container>
			<LoadScreen isLoading={isMapLoaded} />
			{isMapLoaded && <SplashModal />}
			<Nav>
				{/* <Logo href="https://nri.tamu.edu" src={nriLogo} /> */}
				<Logo href="#" src={txmapLogo} />
				<NavList></NavList>

				<Button clear icon={<HelpIcon />} style={{ marginRight: '15px' }}>
					<a
						style={{ textDecoration: 'none' }}
						href="TxMAP Tool User Guide.pdf"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => AddGAEvent('General', 'View User Guide')}
					>
						User Guide
					</a>
				</Button>
				<div style={{ marginRight: 8 }}>
					<PrintMap printServerURL={config.printServerURL} mapView={mapView} />
				</div>
			</Nav>

			<MapWrapper>
				{mapView && (
					<>
						<Legend view={mapView} />
						<Home view={mapView} />
						<Info view={mapView} />
						<Basemap
							view={mapView}
							isMapLoaded={isMapLoaded}
							allowedBasemapTitles={config.mapConfig.allowedBasemapTitles}
						/>
					</>
				)}

				<AccordionContainer
					countyUrl={config.texasCountiesURL}
					countyField={config.texasCountyField}
					categories={config.nriCategories}
					curatedMaps={config.curatedMaps}
					view={mapView}
					printServerURL={config.printServerURL}
					disclaimer={config.disclaimer}
					maxReportAcres={config.maxReportAcres}
				/>
				<Map
					onMapLoaded={mapLoaded}
					mapConfig={config.mapConfig}
					setMapView={setMapView}
					categories={config.nriCategories}
				>
					{mapView && <Loading view={mapView} />}
				</Map>
			</MapWrapper>
		</Container>
	);
};

export default Main;
