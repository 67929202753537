import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import HistoryTracker from './HistoryTracker';

export const GoogleAnalytics = () => {
	useEffect(() => {
		ReactGA.initialize('G-JLV3RHC7N0');
		ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
	}, []);

	return <HistoryTracker onChange={(u) => ReactGA.send({ hitType: 'pageview', page: u })} />;
};

export const AddGAEvent = (category, action, label = '') => {
	ReactGA.event({
		category,
		action,
		label,
	});
};
