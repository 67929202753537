// React imports
import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

// Component
export const Legend = (props) => {
	useEffect(() => {
		if (props.view) {
			loadModules(['esri/widgets/Legend', 'esri/widgets/Expand']).then(([Legend, Expand]) => {
				// do this to remove duplicate layer labels.
				// A truly empty string will cause it to default to service name, hence the single space
				const layers = props.view.map.layers.map((l) => ({ title: ' ', layer: l }));

				const legend = new Legend({
					view: props.view,
					layerInfos: layers,
				});

				var expand = new Expand({
					view: props.view,
					content: legend,
					expanded: true,
					mode: 'floating',
				});

				props.view.ui.add(expand, 'bottom-right');
			});
		}
	}, []); //only fire when showLegend changes

	return null;
};
