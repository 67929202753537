// Copyright 2019 Esri
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//     http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.​

// React //
import React from 'react';
import ReactDOM from 'react-dom';

// Redux //
import { Provider } from 'react-redux';
import { initStore } from './redux/store';

// React Router //
import { BrowserRouter, Route } from 'react-router-dom';

// Components //
import App from './components/App';
import { ToastContainer } from 'calcite-react/Toaster';

// Styles //
import CalciteThemeProvider, { CalciteTheme } from 'calcite-react/CalciteThemeProvider';
import { GlobalStyle } from './styles/global';
import './styles/fonts.css';
import 'react-toastify/dist/ReactToastify.css';

// Create Redux Store
export const store = initStore();

const customTheme = {
	...CalciteTheme,
	palette: {
		...CalciteTheme.palette,
		blue: 'rgb(81, 126, 69)',
		// yes this is green, but the calcite table component only has a bool prop for a blue table
		// go figure
	},
};

// App entry point
ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<CalciteThemeProvider theme={customTheme}>
				<GlobalStyle />
				<ToastContainer />
				<Route path="/" component={App} />
			</CalciteThemeProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
